import api from './services.js'
import service from './room.js'
import { baseUrl } from './host.js'
const env = baseUrl.env
const host = baseUrl[env].host
const port = baseUrl[env].port
//  通过医生id获取其下病人集合
export const selectbymanageid = (params) =>
  api({
    url: host + port.user + 'auth/relation/selectbymanageid',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    params,
  })
//  通过医生id获取其下病人血糖书库
export const getBGbyId = (params) =>
  api({
    url: host + port.cgm + 'cgm/doctor/getBGbyId',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    params: params,
  })
// 通过病人id获取医生的集合
export const selectbybemanagedid = (params) =>
  api({
    url: host + port.user + 'auth/relation/selectbybemanagedid',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    data: params,
  })

// 插入关系
// {
//     "mangerId": "6666666666666666666666666666666",
//     "mangerName": "赵赛",
//     "bemangeredId": "4931c469e89c429bacc5fe514ba6c6d3",
//     "bemangeredName": "gym"
// }
// 我的患者关注
export const insertRelation = (params) =>
  api({
    url: host + port.user + 'auth/relation/insert',
    method: 'post',
    data: params,
  })
// 添加病人到护士站
export const insertScreeRelation = (params) =>
  api({
    url: host + port.user + 'struct/patient/screen/insert',
    method: 'post',
    data: params,
  })

// 删除关系 我的关注
export const deleteRelation = (id) =>
  api({
    url: host + port.user + 'auth/relation/delete',
    method: 'get',
    params: { id: id },
  })

// 通过id获取用户基本信息 auth/getdetailuser
export const getUserInfoById = (id) =>
  api({
    url: host + port.user + 'auth/getdetailuser',
    method: 'get',
    params: { id: id },
  })
// 通过手机号获取用户基本信息 auth/getUserByPhone
export const getUserByPhone = (phone) =>
  api({
    url: host + port.user + 'auth/getUserByPhone',
    method: 'get',
    params: { phone: phone },
  })
// 同意未关注用户 auth/relation/status
export const agreeById = (params) =>
  api({
    url: host + port.user + 'auth/relation/status',
    method: 'get',
    params,
  })
// 通过id获取用户最新血糖
export const getBGbyIDLast = (id) =>
  api({
    url: host + port.cgm + 'cgm/bglatest/byId',
    method: 'get',
    params: { id: id },
  })

// 重新授权
export const getBizidTwo = (id) =>
  api({
    url: host + port.user + 'auth/gj/bizid2',
    method: 'get',
    params: { id: id },
  })

// 添加医院
export const addHospital = (data) =>
  api({
    url: host + port.user + 'auth/struct/insert',
    method: 'post',
    data: data,
  })

// 编辑医院
export const editHospital = (data) =>
  api({
    url: host + port.user + 'auth/struct/update',
    method: 'post',
    data: data,
  })

// 通过id获取我的医院
export const getHospitalById = (params) =>
  api({
    url: host + port.user + 'auth/struct/createId',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    params: params,
  })

// 所有注册医院
export const getAllRegisterHos = () =>
  api({
    url: host + port.user + 'auth/struct/all',
    method: 'get',
  })

// 通过注册的医院
export const getAllPassHos = (params) =>
  api({
    url: host + port.user + 'auth/struct/all',
    method: 'get',
    params,
  })

// 我的医院
export const getCreateIdHospital = (params) =>
  api({
    url: host + port.user + 'auth/struct/createId',
    method: 'get',
    params: params,
  })

// 添加医生或者机构
export const addDoctorOrHospital = (data) =>
  api({
    url: host + port.user + 'auth/doctor/struct/insert',
    method: 'post',
    data: data,
  })

// 根据机构查询医生
export const getDoctorByStruct = (params) =>
  api({
    url: host + port.user + 'auth/doctor/struct/structId',
    method: 'get',
    params: params,
  })

// 通过病人id获取病人基本信息
export const getUserBasic = (params) =>
  api({
    url: host + port.user + 'auth/patient/struct/baseInfo',
    method: 'get',
    params: params,
  })

// 修改病人基本信息
export const editUserBasic = (data) =>
  api({
    url: host + port.user + 'auth/patient/struct/updateInfo',
    method: 'post',
    data: data,
  })
// 注册用户（无需验证码注册）
export const registerDoctor = (data) =>
  api({
    url: host + port.user + 'auth/user/add',
    method: 'post',
    data: data,
  })

// 添加医生和机构接口（医生申请认证）
export const authentication = (params) =>
  api({
    url: host + port.user + 'auth/doctor/struct/insert/authentication',
    method: 'post',
    data: params,
  })

// 通过医生认证
export const agreeByStatus = (params) =>
  api({
    url: host + port.user + 'auth/doctor/struct/status',
    method: 'get',
    params: params,
  })

// 根据医生，查询机构
export const getHospitalByDoctor = (params) =>
  api({
    url: host + port.user + 'auth/doctor/struct/doctorId',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    params: params,
  })

// 根据机构，查询病人
export const getPatientByStructId = (params) =>
  api({
    url: host + port.user + 'auth/patient/struct/structId',
    method: 'get',
    headers: {
      token: localStorage.getItem('token'),
    },
    params: params,
  })
// 多人最新血糖数据
export const getLatestBGAll = (params) =>
  api({
    url: host + port.cgm + 'cgm/bglatest/all',
    method: 'get',
    params: params,
  })

// 根据医生查询病人
export const getPatientByDoctorid = (params) =>
  api({
    url: host + port.user + 'auth/doctor/patient/doctorId',
    method: 'get',
    params,
  })

// 根据患者查询医院
export const getHospitalByPatient = (params) =>
  api({
    url: host + port.user + 'auth/patient/struct/patientId',
    method: 'get',
    params,
  })

// 注册用户（需验证码注册）
export const registerUser = (data) =>
  api({
    url: host + port.user + 'auth/register',
    method: 'post',
    data: data,
  })

// 获取验证码or更新验证码
export const getCode = (phone) =>
  api({
    url: host + port.user + 'auth/getcode?phone=' + phone,
    method: 'post',
  })

// loop闭环远程操作
export const postLoop = (data) =>
  api({
    url: host + port.loop + 'loop/apn',
    method: 'post',
    data: data,
  })

// loop闭环远程操作
export const getLoopOne = (params) =>
  api({
    url: host + port.loop + 'loop/apn/one',
    method: 'get',
    params,
  })

// loop闭环操作列表
export const getLoopHandleList = (params) =>
  api({
    url: host + port.loop + 'loop/apn/all?uid=' + params,
    method: 'get',
  })

// loop取消临时获取配置
export const getLoopOption = (params) =>
  api({
    url: host + port.loop + 'api/v1/profile/current',
    method: 'get',
    params,
  })
//获取最新闭环设备状态
export const loopDevicestatus = (params) =>
  api({
    url: host + port.loop + 'api/v1/devicestatus',
    method: 'get',
    params: params,
  })
//闭环治疗
export const loopTreatments = (params) =>
  api({
    url: host + port.loop + 'api/v1/treatments',
    method: 'get',
    params: params,
  })
//当前用户loop配置文件
export const loopCurrent = (params) =>
  api({
    url: host + port.loop + 'api/v1/profile/current',
    method: 'get',
    params: params,
  })

// 创建白板房间
export const cretaeRoom = (data) =>
  service({
    url: 'https://api.netless.link/v5/rooms',
    method: 'post',
    headers: {
      token:
        'NETLESSSDK_YWs9YlEtczhid3lXbk8yUjFDXyZub25jZT0wMWY1YzJkMC04MmJjLTExZWUtYmY5Yi1iZGRlNzM0YWI4ZjQmcm9sZT0wJnNpZz0xZTFlMzhjYTRkZjQzNDFhMTM1ZTA5MjRkMWQyMzVlMmNmOGQzYTgyZjgxNGEyNGQ2NTEzMzgyNjhiODg2Y2Nk',
      'Content-Type': 'application/json',
      region: 'cn-hz',
    },
    data,
  })

// 生成roomToken
export const cretaeRoomToken = (data) =>
  service({
    url: `https://api.netless.link/v5/tokens/rooms/${data}`,
    method: 'post',
    headers: {
      token:
        'NETLESSSDK_YWs9YlEtczhid3lXbk8yUjFDXyZub25jZT0wMWY1YzJkMC04MmJjLTExZWUtYmY5Yi1iZGRlNzM0YWI4ZjQmcm9sZT0wJnNpZz0xZTFlMzhjYTRkZjQzNDFhMTM1ZTA5MjRkMWQyMzVlMmNmOGQzYTgyZjgxNGEyNGQ2NTEzMzgyNjhiODg2Y2Nk',
      'Content-Type': 'application/json',
      region: 'cn-hz',
    },
    data: {
      lifespan: 3600000,
      role: 'admin',
    },
  })

// 上传开启血糖指导参数
export const openGuid = (data) =>
  api({
    url: host + port.user + 'baiban/add',
    method: 'post',
    data,
  })

// 获取临时token
export const getTempToken = (params) =>
  api({
    url: host + port.user + 'baiban/token',
    method: 'get',
    params,
  })

// 患者接听后获取共享参数(获取接听状态码)
export const getBaibanParams = (params) =>
  api({
    url: host + port.user + 'baiban/one',
    method: 'get',
    params,
  })

// 更新接听状态码A
export const upstateA = (params) =>
  api({
    url: host + port.user + 'baiban/statusa',
    method: 'get',
    params,
  })

// 更新接听状态码B
export const upstateB = (params) =>
  api({
    url: host + port.user + 'baiban/statusb',
    method: 'get',
    params,
  })

// 添加患者
export const addPatient = (data) =>
  api({
    url: host + port.user + 'auth/patient/struct/insert',
    method: 'post',
    data,
  })

// 修改患者的医生
export const changeDoctor = (params) =>
  api({
    url: host + port.user + 'auth/patient/struct/update/doctor',
    method: 'get',
    params: params,
  })

// 添加病例
export const addCases = (data) =>
  api({
    url: host + port.user + 'patient/case/add',
    method: 'post',
    data: data,
  })

// 修改病例
export const editCases = (data) =>
  api({
    url: host + port.user + 'patient/case/edit',
    method: 'post',
    data: data,
  })

//获取某个医院下面的一个病人病历
export const getCases = (params) =>
  api({
    url: host + port.user + `patient/case/get?structId=${params.structId}&patientId=${params.patientId}`,
    method: 'get',
    data: params,
  })

export const upstateYP = (data) =>
  api({
    url: host + port.user + 'yp/update',
    method: 'post',
    data: data,
  })

// 查询所有关注我的人
export const getFollowMe = (params) =>
  api({
    url: host + port.user + 'auth/relation/selectbybemanagedid',
    method: 'get',
    params,
  })

//NS回顾式，获取历史数据，配置文件，设备信息
export const loopHistory = (params) =>
  api({
    url: host + port.loop + 'loop/history',
    method: 'get',
    params: params,
  })

//忘记密码,设置新密码
export const getNewPassword = (data) =>
  api({
    url: host + port.user + 'auth/resetPassword',
    method: 'post',
    data,
  })

//获取所有软件最后的更新情况
export const getLeastVersion = (params) =>
  api({
    url: host + port.user + 'aaps/version/getLeastVersion',
    method: 'get',
    params,
  })

// 获取aaps版本列表
export const getAapsVersionList = (params) =>
  api({
    url: host + port.user + 'aaps/version/getList',
    method: 'get',
    params: params,
  })
