<template>
  <div id="alarmCFG">
    <van-nav-bar :title="'警报'" left-arrow left-text="返回" @click-left="goBackmine" />
    <div style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem">警报设置</div>
    <div style="border-radius: 12px; overflow: hidden; width: 94%; margin-left: 3%; font-weight: bold">
      <van-cell-group>
        <van-cell v-for="(item, i) in alarmCFGList" :key="'cfg' + i" :title="item.title">
          <template #right-icon>
            <el-switch
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            ></el-switch>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem">语音播报</div>
    <div style="border-radius: 12px; overflow: hidden; width: 94%; margin-left: 3%; font-weight: bold">
      <van-cell-group>
        <van-cell v-for="(item, i) in voiceCFGList" :key="'cfg' + i" :title="item.title">
          <template #right-icon>
            <el-switch
              v-if="!item.type"
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            ></el-switch>
            <span v-if="item.type == 'language'">{{ item.language }}</span>
            <span v-if="item.type == 'timeInterval'">{{ item.timeInterval }}分钟</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div style="width: 100%; height: 4rem"></div>
  </div>
</template>
<script>
  import { updatebutoken } from '../../../api/userServices'
  export default {
    name: 'alarmCFG',
    data() {
      return {
        alarmCFGList: [
          {
            name: 'alarmMain',
            title: '警报总开关',
            flag: true,
          },
          {
            name: 'emergentLow',
            title: '紧急低血糖',
            flag: true,
          },
          {
            name: 'low',
            title: '低血糖',
            flag: true,
          },
          {
            name: 'height',
            title: '高血糖',
            flag: true,
          },
          {
            name: 'emergentHigh',
            title: '紧急高血糖',
            flag: true,
          },
          {
            name: 'signalLost',
            title: '信号丢失',
            flag: true,
          },
          {
            name: 'calibrationTip',
            title: '校准提醒',
            flag: true,
          },
        ],
        voiceCFGList: [
          {
            name: 'voicePlayback',
            title: '语音播报血糖值',
            flag: true,
          },
          {
            name: 'language',
            title: '语言',
            type: 'language',
            language: '中文',
          },
          {
            name: 'voiceTrend',
            title: '语音播报趋势值',
            flag: true,
          },
          {
            name: 'voiceChangeRate',
            title: '语音播报变化率',
            flag: true,
          },
          {
            name: 'timeInterval',
            title: '时间间隔',
            type: 'timeInterval',
            timeInterval: '5',
          },
        ],
        configData: {
          alarmMain: '',
          emergentLow: '',
          low: '',
          height: '',
          emergentHigh: '',
          signalLost: '',
          calibrationTip: '',
          voicePlayback: '',
          language: '',
          voiceTrend: '',
          voiceChangeRate: '',
          timeInterval: '',
        },
      }
    },
    mounted() {
      const config = JSON.parse(localStorage.getItem('appuser')).config
      const cobj = JSON.parse(config)
      if (config && JSON.parse(config)) {
        for (let index = 0; index < this.alarmCFGList.length; index++) {
          const element = this.alarmCFGList[index]
          if (cobj[element.name] === '1') {
            this.alarmCFGList[index].flag = true
          } else if (cobj[element.name] === '0') {
            this.alarmCFGList[index].flag = false
          }
        }
        for (let index = 0; index < this.voiceCFGList.length; index++) {
          const element = this.voiceCFGList[index]
          if (cobj.type === 'language') {
            this.voiceCFGList[index].language = cobj[element.name]
          } else if (cobj.type === 'timeInterval') {
            this.voiceCFGList[index].timeInterval = cobj[element.name]
          } else {
            if (cobj[element.name] === '1') {
              this.voiceCFGList[index].flag = true
            } else if (cobj[element.name] === '0') {
              this.voiceCFGList[index].flag = false
            }
          }
        }
      }
    },
    methods: {
      goBackmine() {
        this.$router.push('/MineView')
      },
      changeConfig() {
        for (let index = 0; index < this.alarmCFGList.length; index++) {
          const element = this.alarmCFGList[index]
          if (element.flag) {
            this.configData[element.name] = '1'
          } else {
            this.configData[element.name] = '0'
          }
        }
        for (let index = 0; index < this.voiceCFGList.length; index++) {
          const element = this.voiceCFGList[index]
          if (element.type === 'language') {
            this.configData.language = element.language
          } else if (element.type === 'timeInterval') {
            this.configData.timeInterval = element.timeInterval
          } else {
            if (element.flag) {
              this.configData[element.name] = '1'
            } else {
              this.configData[element.name] = '0'
            }
          }
        }
        updatebutoken({ config: JSON.stringify(this.configData) }).then((res) => {
          const appuser = JSON.parse(localStorage.getItem('appuser'))
          appuser.config = JSON.stringify(this.configData)
          appuser.loopRemote = JSON.parse(localStorage.getItem('appuser')).loopRemote
          localStorage.setItem('appuser', JSON.stringify(appuser))
        })
      },
    },
  }
</script>
<style scoped>
  #alarmCFG {
    background: #f8f8f8;
    text-align: left;
  }
</style>
