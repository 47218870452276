<template>
  <div id="alarmCFG">
    <div
      style="
        width: 100%;
        padding-left: 3%;
        height: 4rem;
        background: #fff;
        position: fixed;
        top: 0;
        z-index: 9;
        border-bottom: 1px solid #ddd;
      "
    >
      <div
        @click="goBackBlood"
        style="
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          left: 0;
          color: #8dd4ff;
          padding-left: 3%;
          font-size: 1.6rem;
        "
      >
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
      <div style="line-height: 4rem; font-size: 1.6rem; font-weight: bold; text-align: center">警报</div>
    </div>
    <div style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem; margin-top: 4rem">警报设置</div>
    <div style="border-radius: 12px; overflow: hidden; width: 94%; margin-left: 3%; font-weight: bold">
      <van-cell-group>
        <van-cell v-for="(item, i) in alarmCFGList" :key="'cfg' + i" :title="item.title" v-show="item.show">
          <template #right-icon>
            <el-switch
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            ></el-switch>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem">血糖目标范围</div>
    <div style="border-radius: 12px; overflow: hidden; width: 94%; margin-left: 3%; font-weight: bold">
      <van-field
        v-model="targetHigh"
        @click="sugarChose('targetHigh')"
        type="number"
        label="血糖目标上限"
        input-align="right"
        readonly
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="targetLow"
        @click="sugarChose('targetLow')"
        type="number"
        label="血糖目标下限"
        input-align="right"
        readonly
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgHigh"
        @click="sugarChose('bgHigh')"
        type="number"
        label="高血糖值"
        input-align="right"
        readonly
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgLow"
        @click="sugarChose('bgLow')"
        type="number"
        label="低血糖值"
        input-align="right"
        readonly
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgVeryLow"
        @click="sugarChose('bgVeryLow')"
        type="number"
        label="极低血糖值"
        input-align="right"
        readonly
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
    </div>
    <!-- 语音播报板块 -->
    <!-- <div
      style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem"
    >
      语音播报
    </div>
    <div
      style="
        border-radius: 12px;
        overflow: hidden;
        width: 94%;
        margin-left: 3%;
        font-weight: bold;
      "
    >
      <van-cell-group>
        <van-cell
          v-for="(item, i) in voiceCFGList"
          :key="'cfg' + i"
          :title="item.title"
        >
          <template #right-icon>
            <el-switch
              v-if="!item.type"
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            >
            </el-switch>
            <span v-if="item.type == 'language'">{{ item.language }}</span>
            <span v-if="item.type == 'timeInterval'"
              >{{ item.timeInterval }}分钟</span
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div> -->
    <div style="width: 100%; height: 8rem"></div>

    <van-popup v-model="sugarChoseShow" position="bottom">
      <van-picker
        :columns="columns"
        @change="onChange"
        show-toolbar
        @cancel="onCancel"
        @confirm="onConfirm"
        :default-index="pickerIndex"
      />
    </van-popup>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  import { updatebutoken } from '../../../api/userServices'
  import Vue from 'vue'
  export default {
    name: 'alarmCFG',
    data() {
      return {
        currentText: '',
        sugarChoseShow: false,
        targetHigh: 10,
        targetLow: 3.9,
        bgHigh: 12.6,
        bgLow: 5.0,
        bgVeryLow: 3.5,
        pickerIndex: '',
        alarmCFGList: [
          {
            name: 'alarmMain',
            title: '警报总开关',
            flag: true,
            show: false,
          },
          {
            name: 'emergentLow',
            title: '紧急低血糖',
            flag: true,
            show: true,
          },
          {
            name: 'low',
            title: '低血糖',
            flag: true,
            show: true,
          },
          {
            name: 'height',
            title: '高血糖',
            flag: true,
            show: true,
          },
          {
            name: 'emergentHigh',
            title: '紧急高血糖',
            flag: true,
            show: true,
          },
          {
            name: 'signalLost',
            title: '信号丢失',
            flag: true,
            show: true,
          },
          {
            name: 'calibrationTip',
            title: '校准提醒',
            flag: true,
            show: true,
          },
        ],
        voiceCFGList: [
          {
            name: 'voicePlayback',
            title: '语音播报血糖值',
            flag: true,
          },
          {
            name: 'language',
            title: '语言',
            type: 'language',
            language: '中文',
          },
          {
            name: 'voiceTrend',
            title: '语音播报趋势值',
            flag: true,
          },
          {
            name: 'voiceChangeRate',
            title: '语音播报变化率',
            flag: true,
          },
          {
            name: 'timeInterval',
            title: '时间间隔',
            type: 'timeInterval',
            timeInterval: '5',
          },
        ],
        configData: {
          alarmMain: '',
          emergentLow: '',
          low: '',
          height: '',
          emergentHigh: '',
          signalLost: '',
          calibrationTip: '',
          voicePlayback: '',
          language: '',
          voiceTrend: '',
          voiceChangeRate: '',
          timeInterval: '',
        },
        columns: [
          '3.0',
          '3.1',
          '3.2',
          '3.3',
          '3.4',
          '3.5',
          '3.6',
          '3.7',
          '3.8',
          '3.9',
          '4.0',
          '4.1',
          '4.2',
          '4.3',
          '4.4',
          '4.5',
          '4.6',
          '4.7',
          '4.8',
          '4.9',
          '5.0',
          '5.1',
          '5.2',
          '5.3',
          '5.4',
          '5.5',
          '5.6',
          '5.7',
          '5.8',
          '5.9',
          '6.0',
          '6.1',
          '6.2',
          '6.3',
          '6.4',
          '6.5',
          '6.6',
          '6.7',
          '6.8',
          '6.9',
          '7.0',
          '7.1',
          '7.2',
          '7.3',
          '7.4',
          '7.5',
          '7.6',
          '7.7',
          '7.8',
          '7.9',
          '8.0',
          '8.1',
          '8.2',
          '8.3',
          '8.4',
          '8.5',
          '8.6',
          '8.7',
          '8.8',
          '8.9',
          '9.0',
          '9.1',
          '9.2',
          '9.3',
          '9.4',
          '9.5',
          '9.6',
          '9.7',
          '9.8',
          '9.9',
          '10.0',
          '10.1',
          '10.2',
          '10.3',
          '10.4',
          '10.5',
          '10.6',
          '10.7',
          '10.8',
          '10.9',
          '11.0',
          '11.1',
          '11.2',
          '11.3',
          '11.4',
          '11.5',
          '11.6',
          '11.7',
          '11.8',
          '11.9',
          '12.0',
          '12.1',
          '12.2',
          '12.3',
          '12.4',
          '12.5',
          '12.6',
          '12.7',
          '12.8',
          '12.9',
          '13.0',
          '13.1',
          '13.2',
          '13.3',
          '13.4',
          '13.5',
          '13.6',
          '13.7',
          '13.8',
          '13.9',
        ],
      }
    },
    mounted() {
      const config = JSON.parse(localStorage.getItem('appuser')).config
      const cobj = JSON.parse(config)
      const appuser = JSON.parse(localStorage.getItem('appuser'))
      this.targetHigh = appuser.targetHigh ? appuser.targetHigh : this.targetHigh
      this.targetLow = appuser.targetLow ? appuser.targetLow : this.targetLow
      this.bgHigh = appuser.bgHigh ? appuser.bgHigh : this.bgHigh
      this.bgLow = appuser.bgLow ? appuser.bgLow : this.bgLow
      this.bgVeryLow = appuser.bgVeryLow ? appuser.bgVeryLow : this.bgVeryLow
      if (config && JSON.parse(config)) {
        for (let index = 0; index < this.alarmCFGList.length; index++) {
          const element = this.alarmCFGList[index]
          if (cobj[element.name] === '1') {
            this.alarmCFGList[index].flag = true
          } else if (cobj[element.name] === '0') {
            this.alarmCFGList[index].flag = false
          }
        }
        for (let index = 0; index < this.voiceCFGList.length; index++) {
          const element = this.voiceCFGList[index]
          if (cobj.type === 'language') {
            this.voiceCFGList[index].language = cobj[element.name]
          } else if (cobj.type === 'timeInterval') {
            this.voiceCFGList[index].timeInterval = cobj[element.name]
          } else {
            if (cobj[element.name] === '1') {
              this.voiceCFGList[index].flag = true
            } else if (cobj[element.name] === '0') {
              this.voiceCFGList[index].flag = false
            }
          }
        }
      }
    },
    methods: {
      sugarChose(text) {
        switch (text) {
          case 'targetHigh':
            console.log(this.targetHigh, 'hig')
            this.pickerIndex = (this.targetHigh - 3) * 10

            break
          case 'targetLow':
            this.pickerIndex = (this.targetLow - 3) * 10
            break
          case 'bgHigh':
            this.pickerIndex = (this.bgHigh - 3) * 10
            break
          case 'bgLow':
            this.pickerIndex = (this.bgLow - 3) * 10
            break
          case 'bgVeryLow':
            this.pickerIndex = (this.bgVeryLow - 3) * 10
            break
        }
        this.currentText = text
        this.sugarChoseShow = true
      },
      onChange() {},
      onCancel() {
        this.sugarChoseShow = false
      },
      onConfirm(value) {
        switch (this.currentText) {
          case 'targetHigh':
            if (Number(value) < Number(this.targetLow)) {
              Toast('血糖上限不能小于血糖下限！')
              this.targetHigh = 10
            } else {
              this.targetHigh = value
              this.changeConfig()
            }
            localStorage.setItem('targetHigh', this.targetHigh)
            break
          case 'targetLow':
            if (Number(value) > Number(this.targetHigh)) {
              Toast('血糖下限不能大于血糖上限！')
              this.targetLow = 3.9
            } else {
              this.targetLow = value
              this.changeConfig()
            }
            localStorage.setItem('targetLow', this.targetLow)
            break
          case 'bgHigh':
            if (Number(value) < Number(this.bgLow) || Number(value) < Number(this.bgVeryLow)) {
              Toast('高血糖值不能小于低血糖值和极低血糖值！')
              this.bgHigh = 12.6
            } else {
              this.bgHigh = value
              this.changeConfig()
            }
            break
          case 'bgLow':
            if (Number(value) > Number(this.bgHigh) || Number(value) < Number(this.bgVeryLow)) {
              Toast('低血糖值不能大于高血糖值和小于极低血糖值！')
              this.bgLow = 5.0
            } else {
              this.bgLow = value
              this.changeConfig()
            }
            break
          case 'bgVeryLow':
            if (Number(value) > Number(this.bgLow) || Number(value) > Number(this.bgHigh)) {
              Toast('极低血糖值不能大于高血糖值和低血糖值！')
              this.bgVeryLow = 3.5
            } else {
              this.bgVeryLow = value
              this.changeConfig()
            }
            break
        }
        this.sugarChoseShow = false
      },

      maxSugarChage(e) {
        if (Number(e) < Number(this.targetLow)) {
          Toast('血糖上限不能小于血糖下限！')
          this.targetHigh = 10
        } else {
          this.targetHigh = e
          this.changeConfig()
        }
        localStorage.setItem('targetHigh', this.targetHigh)
      },
      minSugarChage(e) {
        if (Number(e) > Number(this.targetHigh)) {
          Toast('血糖下限不能大于血糖上限！')
          this.targetLow = 3.9
        } else {
          this.targetLow = e
          this.changeConfig()
        }
        localStorage.setItem('targetLow', this.targetLow)
      },
      highChage(e) {
        if (Number(e) < Number(this.bgLow) || Number(e) < Number(this.bgVeryLow)) {
          Toast('高血糖值不能小于低血糖值和极低血糖值！')
          this.bgHigh = 12.6
        } else {
          this.bgHigh = e
          this.changeConfig()
        }
      },
      lowChage(e) {
        if (Number(e) > Number(this.bgHigh) || Number(e) < Number(this.bgVeryLow)) {
          Toast('低血糖值不能大于高血糖值和小于极低血糖值！')
          this.bgLow = 5.0
        } else {
          this.bgLow = e
          this.changeConfig()
        }
      },
      verylowChage(e) {
        if (Number(e) > Number(this.bgLow) || Number(e) > Number(this.bgHigh)) {
          Toast('极低血糖值不能大于高血糖值和低血糖值！')
          this.bgVeryLow = 3.5
        } else {
          this.bgVeryLow = e
          this.changeConfig()
        }
      },
      goBackBlood() {
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
      },
      changeConfig() {
        for (let index = 0; index < this.alarmCFGList.length; index++) {
          const element = this.alarmCFGList[index]
          if (element.flag) {
            this.configData[element.name] = '1'
          } else {
            this.configData[element.name] = '0'
          }
        }
        for (let index = 0; index < this.voiceCFGList.length; index++) {
          const element = this.voiceCFGList[index]
          if (element.type === 'language') {
            this.configData.language = element.language
          } else if (element.type === 'timeInterval') {
            this.configData.timeInterval = element.timeInterval
          } else {
            if (element.flag) {
              this.configData[element.name] = '1'
            } else {
              this.configData[element.name] = '0'
            }
          }
        }
        const params = {
          config: JSON.stringify(this.configData),
          targetHigh: this.targetHigh,
          targetLow: this.targetLow,
          bgHigh: this.bgHigh,
          bgLow: this.bgLow,
          bgVeryLow: this.bgVeryLow,
        }
        updatebutoken(params).then((res) => {
          if (res.data.code == 200) {
            const appuser = JSON.parse(localStorage.getItem('appuser'))
            appuser.config = JSON.stringify(this.configData)
            appuser.targetHigh = this.targetHigh
            appuser.targetLow = this.targetLow
            appuser.bgHigh = this.bgHigh
            appuser.bgLow = this.bgLow
            appuser.bgVeryLow = this.bgVeryLow
            appuser.loopRemote = JSON.parse(localStorage.getItem('appuser')).loopRemote
            localStorage.setItem('appuser', JSON.stringify(appuser))
            Toast(res.data.msg)
          } else {
            return Toast(res.data.msg)
          }
        })
      },
    },
  }
</script>
<style scoped>
  #alarmCFG {
    background: #f8f8f8;
    text-align: left;
  }
  ::v-deep .van-field__control--right {
    padding-right: 10px;
  }
  ::v-deep .van-field__label {
    color: #323233;
  }
</style>
