<template>
  <div id="selectHospital">
    <van-popup title="选择医院" v-model="dialogFormVisible" position="bottom" :style="{ height: '40rem' }">
      <el-select v-loading="loading" style="margin-top: 1rem" v-model="selectedHospital" placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div v-if="loading" style="margin-top: 1rem">正在查询医院列表，请稍后...</div>
      <el-row style="position: fixed; bottom: 2rem; width: 100%">
        <el-col :span="12">
          <div style="float: right; margin-right: 12px">
            <el-button @click="joinInThehospital">确认</el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="float: left; margin-left: 12px">
            <el-button>取消</el-button>
          </div>
        </el-col>
      </el-row>
    </van-popup>
  </div>
</template>

<script>
  import { getStructList, update } from '../../../api/userServices'
  export default {
    data() {
      return {
        dialogFormVisible: true,
        loading: true,
        options: [],
        selectedHospital: '',
        searchConction: {
          province: '',
          city: '',
          region: '',
          structName: '',
          department: '',
          createName: '',
        },
      }
    },
    methods: {
      getStructBysearch() {
        const params = this.searchConction
        this.options = []
        getStructList(params).then((res) => {
          if (res.data.code === '200') {
            for (let index = 0; index < res.data.data.length; index++) {
              const element = res.data.data[index]
              const obj = {
                value: element.structId,
                label: element.province + element.city + element.region + '[' + element.structName + ']',
              }
              this.options.push(obj)
            }
            setTimeout(() => {
              this.loading = false
            }, 5000)
          }
        })
      },
      joinInThehospital() {
        const _this = this
        if (this.selectedHospital) {
          const structId = this.selectedHospital
          const appuser = JSON.parse(localStorage.getItem('appuser'))
          appuser.structId = structId
          const newData = {
            ...appuser,
            loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
          }
          localStorage.setItem('appuser', JSON.stringify(newData))
          update(appuser).then((res) => {
            if (res.data.code === '2003') {
              _this.$message('申请加入该组织成功，等待管理员添加')
              _this.$emit('closeSelectHospital')
            }
          })
        } else {
          this.$message('请先选择医院')
        }
      },
    },
    mounted() {
      this.getStructBysearch()
    },
  }
</script>
<style scoped></style>
