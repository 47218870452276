<template>
  <div id="CGMDetails" class="flow-container" v-touch:swipe="swipeHandler">
    <div class="dialogDelete" v-if="loading">
      <div style="width: 100%; height: 7rem; color: #fff; margin-top: 1rem">
        <div style="width: 100%; height: 4rem; line-height: 4rem; font-size: 1.6rem">删除蓝牙设备吗？</div>
        <div style="width: 100%; height: 3rem; line-height: 3rem; font-size: 1rem">
          {{ bindingCGM.cgmName }}
        </div>
      </div>
      <div style="width: 100%; height: 4rem; border-top: 1px solid #000">
        <div class="dialogBotton" style="background: #ea2d63" @click="deleteBluetooth">删除</div>
        <div class="dialogBotton" @click="cancelDelete">取消</div>
      </div>
    </div>
    <div v-loading="loading" style="height: 72rem">
      <van-nav-bar title="详情" left-text="返回" @click-left="goBack" left-arrow>
        <template #right>
          <van-icon @click="showDeleteBluetooth" name="delete-o" size="18" color="#ee0a24" />
        </template>
      </van-nav-bar>
      <div style="margin-top: 2rem; height: 220px; width: 100%">
        <div style="width: 200px; height: 200px; margin: auto">
          <img :src="wwBig" style="width: 200px; height: 200px" />
        </div>
      </div>
      <div
        style="width: 88%; margin-left: 6%; height: 13rem; background: #131731; padding-top: 1rem; border-radius: 1rem"
      >
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">名称</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.cgmName }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">连接时间</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.connectionDate }}
          </el-col>
        </el-row>
        <div @click="changeAccount()" style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">状态</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.connectionStatus }}
          </el-col>
        </div>
      </div>

      <div
        style="
          width: 88%;
          margin-top: 1rem;
          margin-bottom: 4rem;
          margin-left: 6%;
          height: 24rem;
          background: #131731;
          padding-top: 1rem;
          border-radius: 1rem;
        "
      >
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">传感器类型</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.cgmType }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">传感器序列号</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.driveNO }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">发射器状态</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.driveStatus }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">电池级别</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.powerPer }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">固件</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.firmware }}
          </el-col>
        </el-row>
        <el-row style="height: 4rem; line-height: 4rem; font-size: 1.6rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 1rem">硬件</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 1rem">
            {{ bindingCGM.hardware }}
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <div style="top: 0%; background: rgb(255, 255, 255); opacity: 0.5;
                    width: 100%; height: 100%; position: fixed;">
            <div>
            </div>
        </div> -->
  </div>
</template>
<script>
  import { upstateYP, getUserInfoById } from '../../../api/relationship'
  import wwBig from '../../../static/cgm/wwBig.png'
  import { Notify, Dialog } from 'vant'
  export default {
    name: 'CGMDetails',
    props: {
      // bindingCGM: {
      //   type: Object,
      //   default: function () {
      //     return {}
      //   },
      // },
    },
    data() {
      return {
        wwBig: wwBig,
        loading: false,

        num: 0, //点击次数
        timer: '', //第一次点击的时间
        timer6: '', //第六次点击的时间

        bindingCGM: {
          cgmName: '',
          cgmAddr: '',
          useDate: '',
          maxLife: '',
          connectionStatus: '',
          connectionDate: '',
          cgmType: '',
          driveNO: '',
          driveStatus: '',
          powerPer: '',
          firmware: '',
          hardware: '',
          status: '',
        },
      }
    },
    mounted() {
      window.setDeviceInformation = this.setDeviceInformation
      window.setAndroidDeviceInformation = this.setAndroidDeviceInformation
      window.disconnectDevice = this.disconnectDevice
      window.libreRestart = this.libreRestart

      this.getWangCgmInfo()
    },
    methods: {
      // 获取旺旺设备信息
      getWangCgmInfo() {
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        getUserInfoById(appuser.id).then((res) => {
          if (res.data.data.yp && res.data.data.cgm == 'yp') {
            console.log('获取设备信息并连接')
            const cgmInfo = JSON.parse(res.data.data.yp)
            this.bindingCGM = {
              cgmName: cgmInfo.cgmName,
              cgmAddr: cgmInfo.cgmAddr,
              // useDate: useDate,
              connectionStatus: cgmInfo.connectionStatus,
              connectionDate: cgmInfo.connectionDate,
              cgmType: cgmInfo.cgmType,
              driveNO: cgmInfo.driveNO,
              driveStatus: cgmInfo.driveStatus,
              powerPer: cgmInfo.powerPer,
              firmware: cgmInfo.firmware,
              hardware: cgmInfo.hardware,
            }
            this.unScan = false
            this.loading = false
            this.$message.success('已连接')
          } else {
            return
          }
        })
      },
      //  连续6次点击显示弹框
      changeAccount() {
        this.num++
        if (this.num == 1) {
          this.timer = new Date().getTime() / 1000
        }
        if (this.num == 3) {
          this.timer6 = new Date().getTime() / 1000
          if (this.timer6 - this.timer <= 2) {
            this.num = 0
            const message = {
              message: '雅培重启',
              body: {},
            }
            const androidMessage = JSON.stringify(message)
            const phoneType = localStorage.getItem('phoneType')
            if (phoneType == 'Android') {
              window.android.libreRestart(androidMessage)
            } else {
              window.webkit.messageHandlers.libreRestart.postMessage(message)
            }
          } else {
            this.num = 0
          }
        }
      },
      goBack() {
        this.$router.push('/MineView')
      },
      swipeHandler(direction) {
        if (direction === 'left') {
          this.$emit('moveDirection', 'left')
        } else if (direction === 'right') {
          this.$router.push({ path: '/', query: { index: 0 } })
        }
      },
      showDeleteBluetooth() {
        this.loading = true
      },
      deleteBluetooth() {
        this.loading = false
        const message = {
          message: '删除蓝牙',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.disconnectDevice(androidMessage)
        } else {
          window.webkit.messageHandlers.disconnectDevice.postMessage(message)
        }

        Notify({ type: 'success', message: '删除成功' })
      },
      cancelDelete() {
        this.loading = false
      },
      setDeviceInformation(device) {
        this.$emit('editBindingCGM', device)
      },
      setAndroidDeviceInformation(device) {
        const info = JSON.parse(JSON.stringify(device))
        this.$emit('editBindingCGM', info)
        return '确定'
      },
    },
  }
</script>
<style scoped>
  #CGMDetails {
    width: 100%;
    background: #21264a;
    z-index: 100;
    position: absolute;
    height: 72rem;
    top: 0;
  }
  .dialogDelete {
    width: 80%;
    height: 12rem;
    background: #222647;
    position: absolute;
    z-index: 2001;
    overflow: hidden;
    left: 10%;
    top: 40%;
    border-radius: 1rem;
  }
  .dialogBotton {
    width: 50%;
    height: 100%;
    float: left;
    line-height: 4rem;
    font-size: 1.2rem;
    color: #fff;
    text-decoration: underline;
  }
  ::v-deep .van-nav-bar__content {
    background: #21264a;
  }
  ::v-deep .van-nav-bar__title {
    color: #fff;
  }
</style>
