export const baseUrl = {
  env: 'pro',
  //线上测试环境
  test: {
    port: {
      user: '20025/',
      event: '20026/',
      cgm: '20027/',
      loop: '20029/',
    },
    host: 'http://59.110.104.80:',
  },
  //本地测试环境u
  zs: {
    port: {
      user: '20025/',
      event: '20026/',
      cgm: '20027/',
      loop: '20029/',
      test: '32051/',
    },
    host: 'http://192.168.1.21:',
  },
  //本地测试环境
  gym: {
    port: {
      user: 'user/',
      event: 'event/',
      cgm: 'cgm/',
      loop: 'loop/',
    },
    host: 'http://192.168.1.194/',
  },
  // 生产环境  支持https  正式上线使用
  pro: {
    port: {
      user: 'user/',
      event: 'event/',
      cgm: 'cgm/',
      loop: 'loop/',
    },
    host: 'https://h5.cgm.go-sugar.cn/',
  },
}
