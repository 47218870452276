<template>
  <div class="protocol-container">
    <van-nav-bar title="服务协议" left-arrow left-text="返回" @click-left="$router.go(-1)" />
    <p>
      总则
      <br />
      <br />
      1、本协议是您与安徽糖仁健康科技有限责任公司（下称“我们”）之间关于使用理糖宝APP、网站的服务所订立的协议。为确保您能正常地使用我们的服务，您应当阅读并遵守《理糖宝服务协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及使用某项服务的单独协议，如您点击勾选即视为您同意并接受协议条款内容。本服务面向成年人，如您是未满18周岁的未成年人，将无权使用我们提供的服务。
      <br />
      <br />
      2、您在进行注册程序过程中，当您点选“同意”按钮时即视为您已仔细阅读本协议，同意接受本协议项下的所有条款，包括接受我们对本协议条款随时所做的任何修改，并愿意受其约束。
      <br />
      <br />
      3、除非您已阅读并接受本协议所有条款，否则您无权使用我们的服务。您同意接受更新后的本协议条款；如您不同意本协议的更新或修订，请您立即停止使用我们的任何服务。当您与我们发生争议时，应以最新的服务协议为准。
      <br />
      <br />
      4、我们在此特别提醒您，在您使用注册前请确认您已确实仔细阅读了本协议，如果您对本协议的任何条款或者将来随时可能修改、补充的条款有异议，您可选择不注册成为我们的用户或不再继续使用我们的任何服务。
      <br />
      <br />
      5、本协议的条款适用于我们提供的各种服务，但当您使用我们某一特定服务时，如该服务另有单独的服务条款、指引或规则，您应遵守本协议及我们不时公布的与该服务相关的服务条款、指引或规则等。前述所有的指引和规则，均构成本协议的一部分。除非本协议另有其他明示规定，新推出的产品或服务、增加或强化目前本服务的任何新功能，均受到本协议之规范。
      <br />
      <br />
      一、我们的服务
      <br />
      <br />
      1、我们的服务自注册或登录理糖宝APP、网站即开始向您提供，并且您可以通过理糖宝APP、网站获得相应的服务。在我们的服务过程中，有可能涉及第三方向您提供服务（以下简称“第三方服务"）。您在使用这些第三方服务时，您在遵守本协议的同时，还需要优先遵守这些第三方服务的相关规则，这些规则会在您首次使用相关服务的时候向您展示，请您留意阅读。第三方服务将由该服务的实际提供方向您承担相应的责任，我们会给予您必要的协助，并在法律要求的必要范围内承担相应的责任。
      <br />
      <br />
      2、为使用本服务，您必须能够自行通过有法律资格的第三方对您提供互联网接入服务，并自行承担以下内容：
      <br />
      <br />
      （1）与服务有关的通信设备（包括但不限于电脑终端、手机终端、调制解调器及其他与接入互联网有关的装置）；
      <br />
      <br />
      （2）通信服务所需的费用（包括但不限于为接入互联网而支付的电话费及宽带费）；
      <br />
      <br />
      3、我们有权根据情况变化调整服务的具体内容，本网站服务内容进行调整的，我们会对前述变更进行通知或公告，或在一定期限内，当您使用相应服务前予以提示。如用户不同意上述调整，您将无法继续使用该服务；如用户继续使用服务，视为用户同意调整后的内容并同意遵守。
      <br />
      <br />
      二、服务暂停、变更与中止条款
      <br />
      <br />
      1、鉴于我们服务的特殊性，我们有权随时变更、中断或终止部分或全部的服务。如变更、中断或终止的服务属于免费服务，我们无需通知您，也无需对任何您或任何第三方承担任何责任。如变更、中断或终止的服务属于付费服务，我们会事先通知您，并征得您的同意。
      <br />
      <br />
      2、您理解，我们需要定期或不定期地对提供本服务的平台或相关的设备进行检修或者维护，如因此类情况而造成本服务在合理时间内的中断，我们无需为此承担任何责任，但我们应尽可能事先进行通告。
      <br />
      <br />
      3、如发生下列任何一种情形，我们有权随时中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任：
      <br />
      <br />
      （1） 您违反国家有关法律法规或我们的管理规定，侵害他人合法权益的；
      <br />
      <br />
      （2） 您丧失使用网站服务的行为能力；
      <br />
      <br />
      （3） 您违反我们发布的政策；
      <br />
      <br />
      （4） 我们认为需要终止服务的情况。
      <br />
      <br />
      4、服务发生变更、中断、终止后，我们仍有以下权利：
      <br />
      <br />
      （1） 服务发生终止后，我们将您的注册数据及以前的行为记录进行匿名化处理；
      <br />
      <br />
      （2） 如您在服务变更、中断、终止前，在我们平台上存在违法行为或违反条款的行为，我们仍可行使本服务条款所规定的权利。
      <br />
      <br />
      5、因不可抗力、网络状况、通讯线路、用户自身过错等技术原因，或其他不可控原因导致您不能正常使用我们的服务，我们不承担相应责任。
      <br />
      <br />
      三、商品交易服务规则
      <br />
      <br />
      1、用户可以使用我们提供的商品交易服务。我们的某些服务是以收费方式提供的，用户使用我们的收费服务时，应当及时、足额支付相应的费用。只有相关款项被及时、足额地支付之后，您才能获得相应的服务。
      <br />
      <br />
      2、用户订购商品时，应当仔细确认所购商品的名称、价格、数量、型号、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人/用户的行为和意思表示视为用户/收货人的行为和意思表示，用户/收货人应对收货人/用户的行为及意思表示的法律后果承担连带责任。
      <br />
      <br />
      3、如果出现降价或促销，除另有说明外，我们不提供价格保护或退款。由于软件类商品或服务含授权/激活信息（包括且不限于邀请码、密码等），为特殊商品，在交易确立完成后，不适用无理由退货，如无质量问题不予退换货。如果您需要任何咨询或帮助，您可随时与我们取得联系。
      <br />
      <br />
      四、用户账号管理
      <br />
      <br />
      1、您可以直接通过理糖宝APP、网站注册您的账户，您承诺在注册您的账户时提供真实的信息，并愿意承担由于提供虚假信息而对您造成的不利后果以及对我们或第三方造成的任何损失。在您申请开通某些服务时，我们会对您的真实身份进行核实，您承诺将积极地予以配合，否则您将无法使用这些服务。
      <br />
      <br />
      2、您一旦注册成功，成为我们的合法用户，将得到一个您的帐号和/或密码。您的帐号和/或密码由您负责保管。您应当对任何以您帐号进行的所有活动和事件负全责。若发现任何非法使用您的帐号或存在安全漏洞的情况，请立即通知我们。
      <br />
      <br />
      3、为便于您使用我们的服务和我们与第三方合作向您提供的服务，在征得您的同意后，我们会将您在我们注册的账户与您在我们的相关合作方的平台注册的账户进行关联。我们将根据法律法规的要求，履行其作为移动互联网信息服务提供者应当履行的义务。
      <br />
      <br />
      4、我们特别提醒您应采取特定措施保护您的账户的安全，包括但不限于妥善保管账户密码、安装防病毒木马软件、定期更改密码、使用后安全退出等。如您账户和密码因黑客行为或您的保管疏忽等情况被盗用，除非您能证明该事件由于我们的故意或重大过失直接导致，否则您应承担被盗用期间产生的一切责任和后果。
      <br />
      <br />
      5、您的账户只限于您本人使用，请勿与他人共用您的账户，否则，我们将无法保证您的数据安全。您了解并同意，使用您的账户实施的任何行为都视为您的行为，即使是他人使用您的账户实施的行为，您也将承担连带责任。如果您发现有人未经授权使用了您的账户，请立即联系并通知我们。
      <br />
      <br />
      五、用户的权利和义务
      <br />
      <br />
      1、用户使用本协议项下服务，应当遵守《中华人民共和国网络安全法》《中华人民共和国电信条例》《中华人民共和国计算机信息网络国际联网暂行规定》和其他有关法律、法规或相关规定、以及本协议的约定，不得存在任何违法违规行为，不得侵犯任何第三方的合法权益。
      <br />
      <br />
      2、用户帐号的所有权归我们所有，用户完成申请注册手续后，获得用户帐号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。因经营需要，我们有权回收用户帐号。
      <br />
      <br />
      3、用户有权更改、删除所注册账号的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。
      <br />
      <br />
      4、用户有责任妥善保管注册帐号信息及帐号密码的安全，用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他成员的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通过客户服务热线通知我们。
      <br />
      <br />
      5、您发现其他用户有违法或违反我们管理规定的行为，可以通过客户服务热线向我们进行反映要求处理。您因我们展示的内容与其他用户发生纠纷的，司法部门可以要求我们根据法律程序提供该案件所需证据材料。
      <br />
      <br />
      6、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，我们有权依据协议终止对违约用户的帐号提供服务。同时，我们保留在任何时候收回用户帐号、用户名的权利。
      <br />
      <br />
      7、用户注册帐号后如果长期不登录该帐号，我们有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。
      <br />
      <br />
      六、使用规则
      <br />
      <br />
      1、用户充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括您所传送的任何内容以及由此产生的任何结果。用户应对我们服务的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对因用户行为而导致的任何损失或损害承担责任。
      <br />
      <br />
      2、用户在理糖宝APP、网站所传送的任何内容并不反映我们的观点或政策，我们对此不承担任何责任。
      <br />
      <br />
      3、用户充分了解并同意，用户须对在理糖宝APP、网站上的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义传播任何信息；不得恶意使用注册帐号导致其他用户误认；否则我们有权立即停止提供服务，收回用户帐号并由用户独自承担由此而产生的一切法律责任。
      <br />
      <br />
      4、我们提供的服务中可能包括广告，用户同意在使用过程中显示我们和第三方供应商、合作伙伴提供的广告。
      <br />
      <br />
      5、用户不得利用我们的服务制作、上载、复制、发送如下内容：
      <br />
      <br />
      （1） 反对宪法所确定的基本原则的；
      <br />
      <br />
      （2） 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      <br />
      <br />
      （3） 损害国家荣誉和利益的；
      <br />
      <br />
      （4） 煽动民族仇恨、民族歧视，破坏民族团结的；
      <br />
      <br />
      （5） 破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br />
      <br />
      （6） 散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br />
      <br />
      （7） 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br />
      <br />
      （8） 侮辱或者诽谤他人，侵害他人合法权益的；
      <br />
      <br />
      （9） 含有法律、行政法规禁止的其他内容的信息。
      <br />
      <br />
      6、我们可依其合理判断，对违反有关法律法规或本协议约定；或侵犯、妨害、威胁任何人权利或安全的内容，或者假冒他人的行为，我们有权依法停止传输任何前述内容，并有权依其自行判断对违反本条款的任何人士采取适当的法律行动，包括但不限于，删除具有违法性、侵权性、不当性等内容，终止违反者的成员资格，阻止其使用全部或部分服务，并且依据法律法规保存有关信息并向有关部门报告等。
      <br />
      <br />
      七、隐私保护
      <br />
      <br />
      1、保护您隐私是我们的一项基本政策，我们保证不对外公开或向第三方提供您的注册资料及您在使用本服务时存储的非公开内容，但下列情况除外：
      <br />
      <br />
      （1） 事先获得您的明确授权；
      <br />
      <br />
      （2） 根据有关的法律法规要求；
      <br />
      <br />
      （3） 按照相关政府主管部门的要求；
      <br />
      <br />
      （4） 为维护社会公众的利益；
      <br />
      <br />
      （5） 为维护我们的合法权益。
      <br />
      <br />
      2、您使用或继续使用我们的服务，即意味着同意我们按照《理糖宝隐私保护政策》收集、使用、储存和分享您的相关信息。详情请参见《理糖宝隐私保护政策》。
      <br />
      <br />
      八、法律责任及免责
      <br />
      <br />
      1、用户违反本协议或相关的服务协议的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿我们与合作公司、关联公司，并使之免受损害。
      <br />
      <br />
      2、用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，我们及合作单位不承担责任。
      <br />
      <br />
      3、本服务协议构成您与我们对服务之约定事项及其他有关事宜的完整协议，除服务条款规定的之外，未赋予服务条款各方其他权利，例如版权、商标权、和其它知识产权和所有权的权利。
      <br />
      <br />
      4、在任何情况下，我们均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用我们的服务而遭受的利润损失，承担责任。尽管本协议中可能含有相悖的规定，我们对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用我们的服务而支付给我们的费用（如有）
      。
      <br />
      <br />
      5、应用开启血糖提醒功能，将默认开启音频后台模式，这么做会大幅降低电池续航能力。
      <br />
      <br />
      6、应用需要在后台运行，以便实时同步和监控血糖数据。这确保了您能获得最新的血糖数据和及时的通知。请在应用权限设置中检查并管理后台活动的权限，以便更好地保护您的隐私。
      <br />
      <br />
      九、违约赔偿
      <br />
      <br />
      您同意保障和维护我们及其他用户的利益，如因您违反有关法律、法规或我们的管理规定而给我们或任何其他第三人造成损失，您同意承担由此造成的损害赔偿责任。
      <br />
      <br />
      十、服务条款修改
      <br />
      <br />
      1、我们有权根据服务情况变更、终止管理规定的各项条款，我们将会通过适当方式向您提示修改内容。
      <br />
      <br />
      2、如果您不同意我们的管理规定所做的修改，我们有权停止服务。如果您继续使用该服务，则视为您接受我们对服务协议相关条款所做的修改。
      <br />
      <br />
      十一&nbsp;、其他条款
      <br />
      <br />
      1、我们郑重提醒用户注意本协议中的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。以上各项条款内容的最终解释权及修改权归我们所有。
      <br />
      <br />
      2、如本服务协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
      <br />
      <br />
      3、本协议签订地为深圳。本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和我们之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交深圳市有管辖权的人民法院管辖。
      <br />
      <br />
      4、若您对我们的服务及本服务协议有任何意见，欢迎垂询来电。客户服务热线：400-014-1088。
      <br />
      <br />
      5、本协议于2023年7月18日首次发布，于2023年7月18日生效。
    </p>
  </div>
</template>

<script>
  export default {
    mounted() {
      localStorage.setItem('loginStatus', true)

      this.$once('hook:beforeDestroy', () => {
        // 页面关闭
        console.log('关闭页面')
        localStorage.setItem('loginStatus', false)
      })
    },
  }
</script>

<style scoped>
  .protocol-container {
    padding: 40px 20px;
    text-align: left;
  }
  ::v-deep .van-nav-bar__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  p {
    line-height: 20px;
    font-size: 14px;
  }
</style>
