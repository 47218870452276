<template>
  <div id="EditDialog">
    <van-nav-bar v-if="registerType" title="个人信息" />
    <van-nav-bar v-else title="个人信息" left-arrow @click-left="goBackMineView" />

    <div class="info">个人信息</div>

    <div class="file">
      <div>
        <span>账号</span>
        <span style="color: #868686">
          {{ userInfo.iphone }}
          <van-icon name="arrow" />
        </span>
      </div>
      <div>
        <span>头像</span>
        <span style="color: #868686">
          <img
            @click="editAva(userInfo.images)"
            :src="
              !userInfo.images || userInfo.images == '未设置'
                ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
                : userInfo.images
            "
            style="width: 40px; height: 40px; border-radius: 5px"
          />
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="nameShow = true">
        <span>昵称</span>
        <span style="color: #868686">
          {{ userInfo.name ? userInfo.name : '无' }}
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="passwordShow = true">
        <span>密码</span>
        <span style="color: #868686">
          ******
          <van-icon name="arrow" />
        </span>
      </div>
      <div @click="areaShow = true">
        <span>城市</span>
        <span style="color: #868686">
          {{ userInfo.city ? userInfo.city : '无' }}
          <van-icon name="arrow" />
        </span>
      </div>
      <div @click="heightShow = true">
        <span>身高</span>
        <span style="color: #868686">
          {{ userInfo.height + ' cm' }}
          <van-icon name="arrow" />
        </span>
      </div>
      <div @click="weightShow = true">
        <span>体重</span>
        <span style="color: #868686">
          {{ userInfo.weight + ' kg' }}
          <van-icon name="arrow" />
        </span>
      </div>
    </div>
    <div class="info">账号管理</div>
    <div class="zhuxiao" @click="logOff">
      <span>注销账号</span>
      <span style="color: #868686">
        <van-icon name="arrow" />
      </span>
    </div>

    <div style="margin: 16px" v-if="registerType">
      <van-button round block type="info" native-type="submit" @click="goSugar">提交</van-button>
    </div>

    <el-dialog title="上传头像" :show-close="false" :visible.sync="showUpdateFile" width="80%">
      <el-upload
        v-loading="loading"
        class="avatar-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="fileChange"
        :auto-upload="false"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="headSculpture" :src="headSculpture" class="avatar" style="width: 12rem; height: 12rem" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUpdateFile = false">取 消</el-button>
        <el-button type="primary" @click="updateImageFile">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 昵称修改 -->
    <van-popup v-model="nameShow" position="bottom" class="kedu" :style="{ height: '140px' }">
      <div class="btn">
        <span class="first" @click="nameShow = false">取消</span>
        <span @click="onConfirmName">确认</span>
      </div>
      <input class="nameBox" v-model="userInfo.name" />
    </van-popup>
    <!-- 密码修改 -->
    <van-popup v-model="passwordShow" position="bottom" class="kedu" :style="{ height: '140px' }">
      <div class="btn">
        <span class="first" @click="passwordShow = false">取消</span>
        <span @click="onConfirmPassword">确认</span>
      </div>
      <input class="nameBox" v-model="userInfo.password" />
    </van-popup>
    <!-- 身高修改 -->
    <van-popup v-model="heightShow" position="bottom" class="kedu" :style="{ height: '230px' }">
      <div class="btn">
        <span class="first" @click="onCancelCm">取消</span>
        <span @click="onConfirmCm">确认</span>
      </div>
      <Ruler v-model="height" />
    </van-popup>
    <!-- 体重修改 -->
    <van-popup v-model="weightShow" position="bottom" class="kedu" :style="{ height: '230px' }">
      <div class="btn">
        <span class="first" @click="onCancelKg">取消</span>
        <span @click="onConfirmKg">确认</span>
      </div>
      <RulerKg v-model="weight" />
    </van-popup>
    <!-- 城市修改 -->
    <van-popup v-model="areaShow" round position="bottom">
      <van-area title="选择城市" :area-list="areaList" @cancel="areaShow = false" @confirm="areaSave" />
    </van-popup>
  </div>
</template>
<script>
  import Ruler from '@/pages/mineView/component/ruler.vue'
  import RulerKg from '@/pages/mineView/component/rulerKg.vue'
  import { areaList } from '@vant/area-data'
  import options from './data'
  import { Toast } from 'vant'
  import camera from '../../../static/pic/camera.png'
  import * as qiniu from 'qiniu-js'
  import { update, getQniuToken, loginOff } from '../../../api/userServices'
  export default {
    name: 'EditDialog',
    props: ['registerType'],
    components: { Ruler, RulerKg },
    data() {
      return {
        weight: 65,
        height: 175,
        heightShow: false,
        weightShow: false,
        areaShow: false,
        phoneShow: false,
        passwordShow: false,
        areaList: areaList,
        nameShow: false,
        options: options,
        dialogVisible: false,
        loading: false,
        headSculpture: '',
        showUpdateFile: false,
        filePath: '',
        camera: camera,
        fileList: [],
        userInfo: {},
        tableData: [
          {
            label: '昵称',
            value: '222',
            source: 'name',
          },
          {
            label: '性别',
            value: '222',
            source: 'sex',
          },
          {
            label: '手机号',
            value: '222',
            source: 'iphone',
          },
          {
            label: '城市',
            value: '222',
            source: 'city',
          },
        ],
      }
    },
    mounted() {
      this.setUserInfo()
    },
    methods: {
      // 昵称
      onConfirmName() {
        this.update()
        this.nameShow = false
      },
      // 密码
      onConfirmPassword() {
        this.update()
        this.passwordShow = false
      },
      // 身高
      formatTooltipCm(value) {
        return `${value} cm`
      },
      onCancelCm() {
        this.heightShow = false
      },
      onConfirmCm() {
        this.userInfo.height = this.height
        this.heightShow = false
        this.update()
      },
      // 体重
      formatTooltipKg(value) {
        return `${value} kg`
      },
      onCancelKg() {
        this.weightShow = false
      },
      onConfirmKg() {
        this.userInfo.weight = this.weight
        this.weightShow = false
        this.update()
      },
      // 注销账户
      logOff() {
        this.$dialog
          .confirm({
            title: '注销账户',
            message: '您确定要注销账户吗?\n如需找回，请联系客服！',
          })
          .then(() => {
            const appuser = localStorage.getItem('appuser')
            const params = {
              uid: JSON.parse(appuser).id,
            }
            loginOff(params).then((res) => {
              if (res.data.code == 200) {
                localStorage.removeItem('appuser')
                localStorage.removeItem('hospital')
                localStorage.setItem('loginStatus', false)
                localStorage.removeItem('token', false)
                this.$router.push('/login')
              }
            })
          })
          .catch(() => {
            // on cancel
          })
      },
      editAva(image) {
        this.$router.push({
          path: '/editAva',
          query: {
            image,
          },
        })
      },
      goSugar() {
        this.$router.push({
          path: '/BloodSugar',
          query: { index: 0 },
        })
        Toast('登录成功！')
      },
      // 保存城市选择
      areaSave(e) {
        this.userInfo.city = e[0].name + e[1].name + e[2].name
        this.areaShow = false
        this.update()
      },
      goBackMineView() {
        // this.$emit("goBackMineView");
        this.$router.push('MineView')
      },
      updateImageFile() {
        this.loading = true
        this.updateHeadSculpture()
        setTimeout(() => {
          this.showUpdateFile = false
          this.loading = false
        }, 2000)
      },
      openUpload() {
        this.showUpdateFile = true
      },
      fileChange(file) {
        this.fileList.push(file.raw)
        this.file = file.raw
        this.fileList = []
        this.loading = true
        const userId = JSON.parse(localStorage.getItem('appuser')).id
        const suffix = this.file.name.split('.')[1] // 后缀名
        const timestamp = Date.parse(new Date())
        const key = userId + timestamp + '.' + suffix // key为上传后文件名 必填
        const config = { useCdnDomain: true, region: qiniu.region.z0 }
        const putExtra = { fname: this.file, params: {}, mimeType: null }
        const observe = {
          next(res) {
            console.log(res)
          },
          error(err) {
            console.log(err)
          },
          /* 完成后的操作 */
          complete(res) {
            // 上传成功以后会返回key 和 hash  key就是文件名了！
            console.log(res)
            // let fileUrl =baseUrl + '/' + res.key
          },
        }
        // 开始上传  token 需要找后端获取(单独的方法)
        getQniuToken().then((res) => {
          const token = res.data.data
          const headers = qiniu.getHeadersForMkFile(token)
          // file 是获取到的文件对象
          // key 是文件名字，传null将使用hash值来当作文件名
          const observable = qiniu.upload(this.file, key, token, headers, putExtra, config)
          this.subscription = observable.subscribe(observe)
          setTimeout(() => {
            this.headSculpture = 'https://oss.cgm.go-sugar.cn/' + key
            this.loading = false
          }, 2000)
        })
      },
      editUserInfo(item) {
        this.dialogVisible = true
      },
      setUserInfo() {
        this.userInfo = JSON.parse(localStorage.getItem('appuser'))
        if (this.userInfo.height) {
          this.height = this.userInfo.height
        } else {
          this.userInfo.height = 175
        }
        if (this.userInfo.weight) {
          this.weight = this.userInfo.weight
        } else {
          this.userInfo.weight = 65
        }
        this.headSculpture =
          !this.userInfo.images || this.userInfo.images == '未设置'
            ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
            : this.userInfo.images
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].value = this.userInfo[this.tableData[index].source]
            ? this.userInfo[this.tableData[index].source]
            : '未设置'
        }
      },
      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!')
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      },
      canceleEdit() {
        this.setUserInfo()
        this.dialogVisible = false
      },
      updateChange() {
        this.update()
        this.dialogVisible = false
      },
      // 头像修改完立即上传
      updateHeadSculpture() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        user.images = this.headSculpture
        update(user).then((res) => {
          if (res.data.code === '2003') {
            const newData = {
              ...user,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('appuser', JSON.stringify(newData))
            this.setUserInfo()
          } else {
            Toast(res.data.msg)
          }
        })
      },
      update() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        user.id = user.id ? user.id : '未设置'
        user.name = this.userInfo.name ? this.userInfo.name : '未设置'
        user.password = this.userInfo.password ? this.userInfo.password : '未设置'
        user.createdate = user.createdate ? user.createdate : '未设置'
        user.images = this.headSculpture ? this.headSculpture : '未设置'
        user.city = this.userInfo.city ? this.userInfo.city : '未设置'
        user.iphone = this.userInfo.iphone ? this.userInfo.iphone : '未设置'
        user.cgm = user.cgm ? user.cgm : '未设置'
        user.weight = this.userInfo.weight
        user.height = this.userInfo.height
        update(user).then((res) => {
          if (res.data.code === '2003') {
            const newData = {
              ...user,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('appuser', JSON.stringify(newData))
            this.setUserInfo()
            Toast(res.data.msg)
          } else {
            Toast(res.data.msg)
          }
        })
      },
    },
  }
</script>
<style scoped lang="less">
  #EditDialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
    background: #f8f8f8;
  }
  * {
    box-sizing: border-box;
    i {
      font-style: normal;
    }
  }
  .van-cell {
    margin-bottom: 2px;
  }
  .el-dialog {
    border-radius: 1rem !important;
  }
  .el-input__inner {
    color: #88d0fc !important;
    border: none !important;
  }
  ::v-deep .el-dialog__footer {
    text-align: center !important;
  }
  ::v-deep .kedu {
    height: 170px;
    .el-slider {
      margin-top: 60px;
      .el-slider__runway {
        width: 96%;
        margin: 0 0 0 2%;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      height: 40px;
      border-bottom: 1px solid #f7f8f9;
      font-size: 14px;
      color: #3f76f0;
      .first {
        color: #b2b2b2;
      }
    }
  }
  .file {
    background-color: #fff;
    width: 94%;
    margin-left: 3%;
    padding: 0 16px;
    border-radius: 10px;
    font-size: 15px;
    margin-top: 10px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 47px;
      border-bottom: 1px solid #efefef;
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 47px;
      }
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
  .nameBox {
    padding-left: 20px;
    width: 90%;
    height: 40px;
    margin-top: 20px;
    border: 1px solid #6288e2;
    border-radius: 10px;
  }
  ::v-deep .van-ellipsis {
    line-height: 44px;
  }
  .zhuxiao {
    background-color: #fff;
    width: 94%;
    margin-left: 3%;
    padding: 0 16px;
    border-radius: 10px;
    font-size: 15px;
    margin-top: 10px;
    height: 47px;
    line-height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info {
    margin-top: 15px;
    margin-left: 3.4%;
    font-size: 13px;
    color: #a7a8aa;
    text-align: left;
  }
</style>
