<template>
  <div class="ruler-view" ref="canvasContainer">
    <canvas ref="canvas" class="canvas-display"></canvas>
  </div>
</template>

<script>
  export default {
    name: 'RulerView',
    props: {
      value: {
        type: Number,
        default: 0,
      },
    },
    watch: {
      // 当外部传入的 value 变化时，同步更新 currentValue
      value(newValue) {
        this.currentValue = newValue
        this.drawRuler()
      },
      // 当 currentValue 变化时，通知父组件更新 value
      currentValue(newValue) {
        this.$emit('input', newValue) // 触发 v-model 更新
      },
    },
    data() {
      return {
        canvas: null,
        ctx: null,
        widthSize: 0,
        heightSize: 0,
        currentValue: this.value, // 初始值
        minValue: 0, // 最小值
        maxValue: 150, // 最大值为 150 kg
        scaleGap: 8, // 增大刻度之间的距离
        selectedLineHeight: 28, // 增大选中线条高度
        circleRadius: 4, // 增大蓝色圆圈的半径
        shortLineHeight: 12, // 增大短刻度高度
        longLineHeight: 22, // 增大长刻度高度
        lastTouchX: 0,
        isDragging: false,
        dpr: window.devicePixelRatio || 1, // 设备像素比率，确保清晰度
      }
    },
    methods: {
      initCanvas() {
        this.canvas = this.$refs.canvas
        this.ctx = this.canvas.getContext('2d')
        this.widthSize = this.$refs.canvasContainer.clientWidth
        this.heightSize = this.$refs.canvasContainer.clientHeight

        // 设置 canvas 实际分辨率，并应用 dpr
        this.canvas.width = this.widthSize * this.dpr
        this.canvas.height = this.heightSize * this.dpr
        this.ctx.scale(this.dpr, this.dpr) // 缩放绘图上下文

        this.drawRuler()
      },
      drawRuler() {
        const baselineY = this.heightSize - 40 // 调整基线位置
        const startX = this.widthSize / 2 - this.currentValue * this.scaleGap

        this.ctx.clearRect(0, 0, this.widthSize * this.dpr, this.heightSize * this.dpr)

        // 绘制刻度线和数值
        for (let i = this.minValue; i <= this.maxValue; i++) {
          const x = startX + i * this.scaleGap

          // 限制刻度线仅绘制在 canvas 可见区域和设定刻度范围内
          if (x < 0 || x > this.widthSize || i < this.minValue || i > this.maxValue) continue

          const lineHeight = i % 10 === 0 ? this.longLineHeight : this.shortLineHeight
          this.ctx.beginPath()
          this.ctx.moveTo(x, baselineY - lineHeight)
          this.ctx.lineTo(x, baselineY)
          this.ctx.strokeStyle = i % 10 === 0 ? 'lightgray' : '#e0e0e0'
          this.ctx.lineWidth = 1
          this.ctx.stroke()

          if (i % 10 === 0) {
            this.ctx.font = '12px Arial' // 增大字体大小
            this.ctx.textAlign = 'center'
            this.ctx.fillStyle = 'gray'
            this.ctx.fillText(i.toString(), x, baselineY + 16) // 将刻度标签靠近基线
          }
        }

        // 绘制底线，限制底线仅在 minValue 和 maxValue 范围内
        this.ctx.strokeStyle = 'lightgray'
        this.ctx.lineWidth = 1
        this.ctx.beginPath()
        this.ctx.moveTo(startX + this.minValue * this.scaleGap, baselineY)
        this.ctx.lineTo(startX + this.maxValue * this.scaleGap, baselineY)
        this.ctx.stroke()

        // 绘制蓝色选中线和中间的刻度值
        this.ctx.strokeStyle = '#396ee0' // 使用新的蓝色
        this.ctx.lineWidth = 1.5
        this.ctx.beginPath()
        this.ctx.moveTo(this.widthSize / 2, baselineY - this.selectedLineHeight)
        this.ctx.lineTo(this.widthSize / 2, baselineY)
        this.ctx.stroke()

        // 绘制蓝色圆圈和白色镂空
        this.ctx.beginPath()
        this.ctx.arc(this.widthSize / 2, baselineY - this.selectedLineHeight, this.circleRadius, 0, Math.PI * 2)
        this.ctx.fillStyle = '#396ee0' // 使用新的蓝色
        this.ctx.fill()

        this.ctx.beginPath()
        this.ctx.arc(this.widthSize / 2, baselineY - this.selectedLineHeight, this.circleRadius / 2, 0, Math.PI * 2)
        this.ctx.fillStyle = 'white'
        this.ctx.fill()

        // 在蓝色刻度线旁边显示当前值，并添加 "kg" 单位
        this.ctx.font = '14px Arial' // 增大字体大小
        this.ctx.fillStyle = 'black'
        this.ctx.fillText(
          `${Math.round(this.currentValue)} kg`,
          this.widthSize / 2,
          baselineY - this.selectedLineHeight - 10
        ) // 靠近蓝色线条
      },
      startDrag(event) {
        this.isDragging = true
        this.lastTouchX = event.clientX || event.touches[0].clientX
      },
      onDrag(event) {
        if (!this.isDragging) return

        const touchX = event.clientX || (event.touches ? event.touches[0].clientX : 0)
        const dx = touchX - this.lastTouchX
        this.lastTouchX = touchX

        this.currentValue -= dx / this.scaleGap
        this.currentValue = Math.max(this.minValue, Math.min(this.maxValue, this.currentValue))

        this.drawRuler()
      },
      stopDrag() {
        this.isDragging = false
        this.currentValue = Math.round(this.currentValue) // Snap to the nearest integer
        this.drawRuler()
      },
    },
    mounted() {
      this.initCanvas()
      window.addEventListener('resize', this.initCanvas)
      this.canvas.addEventListener('mousedown', this.startDrag)
      this.canvas.addEventListener('mousemove', this.onDrag)
      this.canvas.addEventListener('mouseup', this.stopDrag)
      this.canvas.addEventListener('mouseleave', this.stopDrag)
      this.canvas.addEventListener('touchstart', this.startDrag)
      this.canvas.addEventListener('touchmove', this.onDrag)
      this.canvas.addEventListener('touchend', this.stopDrag)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.initCanvas)
      this.canvas.removeEventListener('mousedown', this.startDrag)
      this.canvas.removeEventListener('mousemove', this.onDrag)
      this.canvas.removeEventListener('mouseup', this.stopDrag)
      this.canvas.removeEventListener('mouseleave', this.stopDrag)
      this.canvas.removeEventListener('touchstart', this.startDrag)
      this.canvas.removeEventListener('touchmove', this.onDrag)
      this.canvas.removeEventListener('touchend', this.stopDrag)
    },
  }
</script>

<style scoped>
  .ruler-view {
    position: relative;
    width: 100%;
    height: 180px; /* 适当增大组件高度 */
  }

  .canvas-display {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>
