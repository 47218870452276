<template>
  <div class="barChart" v-show="rateChart">
    <div style="float: left; margin-left: 2rem">
      <div class="bloodRate1" style="background: rgb(255, 168, 0); width: 3rem"></div>
      <div class="bloodRate2" style="background: rgb(255, 212, 38); width: 3rem"></div>
      <div class="bloodRate3" style="background: rgb(101, 209, 114); width: 3rem"></div>
      <div class="bloodRate4" style="background: rgb(255, 113, 113); width: 3rem"></div>
      <div class="bloodRate5" style="background: rgb(238, 37, 37); width: 3rem"></div>
    </div>
    <div style="color: #333; margin-left: 0.5rem; text-align: left; float: left; font-size: 0.8rem">
      <div class="bloodRate1">{{ bloodRate[0] == 'NaN' ? '-' : bloodRate[0] }}% 非常高</div>
      <div class="bloodRate2" style="margin-left: -6.8rem">{{ bloodRate[1] == 'NaN' ? '-' : bloodRate[1] }}% 高</div>
      <div class="bloodRate3">{{ bloodRate[2] == 'NaN' ? '-' : bloodRate[2] }}% 正常</div>
      <div class="bloodRate4" style="margin-left: -6.8rem">{{ bloodRate[3] == 'NaN' ? '-' : bloodRate[3] }}% 低</div>
      <div class="bloodRate5">{{ bloodRate[4] == 'NaN' ? '-' : bloodRate[4] }}% 非常低</div>
    </div>
  </div>
</template>

<script>
  import { getRate } from '@/pages/sugerData/getSugerData'
  export default {
    props: {
      SugerData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        classList: ['bloodRate1', 'bloodRate2', 'bloodRate3', 'bloodRate4', 'bloodRate5'],
        bloodRate: [12, 24, 24, 12, 28],
        rateChart: false,
      }
    },
    mounted() {
      // this.getBarValue()
    },
    methods: {
      getBarValue() {
        // 平均血糖柱状图数据
        this.bloodRate[0] = getRate(this.SugerData, 13.9, 100)
        this.bloodRate[1] = getRate(this.SugerData, 10, 13.9)
        this.bloodRate[2] = getRate(this.SugerData, 3.8, 10)
        this.bloodRate[3] = getRate(this.SugerData, 3, 3.8)
        this.bloodRate[4] = getRate(this.SugerData, 0, 3)
        this.bloodRate = [...this.bloodRate]
        this.setBloodRate()
      },
      setBloodRate() {
        for (let index = 0; index < this.classList.length; index++) {
          const element = this.classList[index]
          document.getElementsByClassName(element)[0].style.height = this.bloodRate[index] / 10 + 'rem'
          document.getElementsByClassName(element)[1].style.height = this.bloodRate[index] / 10 + 'rem'
          document.getElementsByClassName(element)[1].style['line-height'] = this.bloodRate[index] / 10 + 'rem'
        }
        this.rateChart = true
      },
    },
    watch: {
      SugerData: {
        handler(newValue) {
          this.getBarValue()
        },
        deep: true, // 深度监听对象内部属性变化
      },
    },
  }
</script>

<style scoped>
  [class^='bloodRate'] {
    min-height: 3px;
    margin-top: 1px;
    border-radius: 2px;
  }
</style>
