<template>
  <div class="protocol-container">
    <van-nav-bar title="隐私政策" left-arrow left-text="返回" @click-left="$router.go(-1)" />
    <p>
      本隐私政策适用于安徽糖仁健康科技有限责任公司及关联公司（以下统称“我们”）的理糖宝APP及与之相关联的网站。本隐私政策旨在帮助您了解我们如何收集、利用、存储和保护您的个人信息，同时我们也将告知您享有何种权利。
      <br />
      <br />
      <span>
        请您仔细阅读并充分理解本隐私政策协议，重点内容我们已经用粗体表示，请您特别关注。接受或同意本隐私政策表示您：
      </span>
      <br />
      <br />
      ●&nbsp;确认您已达到接受本隐私政策所需的法定年龄；
      <br />
      <br />
      ●&nbsp;代表您自己或您合法代理的个人同意接受本隐私政策；
      <br />
      <br />
      ●&nbsp;明确承认您对我们产品和/或服务的使用受本隐私政策约束。
      <br />
      <br />
      最近更新日期：2023年7月18日。
      <br />
      <br />
      如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：
      <br />
      <br />
      电话：400-014-1088
      <br />
      <br />
      注册地址：安徽省淮北市相山区桓谭路66号国购广场(商业)3幢A座
      <br />
      <br />
      办公地址：安徽省淮北市相山区国购金融中心B座

      <br />
      <br />
      目录：
      <br />
      <br />
      引言
      <br />
      <br />
      一、我们如何收集您的个人信息
      <br />
      <br />
      二、我们如何使用您的个人信息
      <br />
      <br />
      三、我们如何存储和保护您的个人信息
      <br />
      <br />
      四、您的权利
      <br />
      <br />
      五、我们如何处理未成年人的个人信息
      <br />
      <br />
      六、本隐私政策如何更新
      <br />
      <br />
      七、如何联系我们
      <br />
      <br />
      八、关键词定义

      <br />
      <br />
      <span>引言</span>
      <br />
      <br />
      我们非常重视用户的隐私和个人信息保护。您在使用我们的产品和/或服务时，我们可能会收集和使用您的信息。我们希望通过《理糖宝隐私政策》（“本隐私政策”）向您说明我们的业务情形和对应需要收集您个人信息的类型，以及我们为您提供访问、更新、删除这些信息的途径和保护措施，还有当我们共享、转让、公开披露您个人信息时需要遵守的规则。本隐私政策与您所使用的理糖宝服务以及该服务所包括的各种业务功能（以下统称“我们的产品和/或服务”）息息相关，希望您在使用我们的产品和/或服务前仔细阅读并确认您已经充分理解本隐私政策所记载的内容（特别是加粗字体的内容），并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们用简明扼要的表述，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品和/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、存储和保护您的相关信息。
      <br />
      <br />
      需要特别说明的是，本隐私政策仅适用于本隐私政策定义的产品和/或服务。当您使用第三方机构直接向您提供的产品或服务并向其提供您的个人信息时，我们建议您在使用之前仔细查看他们的隐私政策，了解他们如何处理您的信息，以便审慎决策。

      <br />
      <br />
      <span>一、我们如何收集您的个人信息</span>
      <br />
      <br />
      <span>我们会通过如下三种渠道获取您的个人信息：</span>
      <br />
      <br />
      1、您直接提供的信息：我们可能收集并存储您在使用理糖宝APP服务时向我们提供的信息，包括您在我们页面中在线填写信息时、申请产品和/或服务时、解决争议或就理糖宝APP服务与我们联系时所提供的信息，以及您在使用我们服务过程中产生的关于您的交易和活动的信息。&nbsp;
      <br />
      <br />
      2、我们主动收集的信息：在您使用理糖宝APP及相关服务过程中（包括在后台运行状态中），我们会收集您的硬件产品、计算机、手机及其他访问设备发送给我们的信息，&nbsp;包括您使用该硬件产品已发生的血糖数据信息。
      <br />
      <br />
      3、我们从其他来源取得的信息：在取得您同意的前提下，我们还可能关联公司及合作伙伴及依法成立并合法留存您的相关信息的第三方机构获取您的相关信息。
      <br />
      <br />
      <span>（一）各项服务</span>
      <br />
      <br />
      <span>1、注册、登录</span>
      <br />
      <br />
      您需要登录理糖宝APP，在此过程中，为对您的账号进行验证以实现登录的目的，我们将收集、存储您的
      <span>手机号码</span>
      用于账号登录。收集这些信息是用于为您提供账号登录服务以及保障您的账号安全，若您不提供上述信息，则您将无法正常登录理糖宝APP。
      <br />
      <br />
      您可自行选择补充提供
      <span>出生日期、身高、体重、性别、糖尿病史数据等信息,</span>
      这些信息并非该业务功能运行所必需，但这些信息对提供服务、改善服务质量、研发新产品或服务等有非常重要的意义。若您不提供上述信息，不影响您使用理糖宝APP的服务。
      <br />
      <br />
      <span>2、设备连接</span>
      <br />
      <br />
      为了您能够连接您使用的我们的产品，理糖宝APP会向您申请下列与个人信息相关的系统权限：
      <span>蓝牙、设备位置信息、照片文件读取权限、拍照权限。</span>
      如果您不授权，将会导致我们无法提供血糖数据监测业务功能，但不影响其他功能与服务的正常使用。
      <br />
      <br />
      <span>3、共享数据</span>
      <br />
      <br />
      为了方便您向例如亲友、医生等共享自己的血糖数据，我们会收集和存储您的关注、关注您的人的信息，收集这些信息是用于为您更好地进行数据共享。若您不提供上述信息，则您将无法使用该业务功能，但不影响其他功能与服务的正常使用。
      <br />
      <br />
      <span>4、生成报告</span>
      <br />
      <br />
      您选择使用该项业务功能时，您需要向我们提供或允许我们收集的必要信息包括：
      <span>手机号、产品信息（型号，Mac地址等），血糖数据信息。</span>
      我们会将上述信息用于为您提供准确的血糖报告。如果您不提供上述信息，则您无法使用该项业务功能，但不影响其他功能与服务的正常使用。在您使用该业务功能时，理糖宝APP会向您申请授权下列系统权限：
      <span>网络权限，文件读写权限</span>
      。我们会基于这两项权限，为您发送理糖宝APP生成的报告。如果您不授权，将会导致我们无法提供该业务功能，但不影响其他功能与服务的正常使用。
      <br />
      <br />
      <span>5、后台运行</span>
      <br />
      <br />
      应用需要在后台运行，以便实时同步和监控血糖数据。这确保了您能获得最新的血糖数据和及时的通知。请在应用权限设置中检查并管理后台活动的权限，以便更好地保护您的隐私。
      <br />
      <br />
      <span>6、设备权限调用说明</span>
      <br />
      <br />
      为了您能够连接及使用理糖宝产品或服务，理糖宝平台会向您申请下列与个人信息相关的系统权限:
      <br />
      <br />
      (1)权限名称:NFC 权限用途:用于激活理糖宝产品 收集必要性说明:必须，若拒绝则无法连接CGM监测仪
      <br />
      <br />
      (2)权限名称:蓝牙 权限用途:用于连接理糖宝产品的蓝牙设备 收集必要性说明:必须，若拒绝则无法连接CGM监测仪
      <br />
      <br />
      (3)权限名称:位置与定位 权限用途:用于定位理糖宝产品蓝牙设备的位置及 其与移动终端的距离，以优化信号的搜索与匹配
      收集必要性说明:必须，若拒绝则无法连接CGM监测仪
      <br />
      <br />
      (4)权限名称:相机 权限用途:用于分享理糖宝产品的血糖图 收集必要性说明:不必须
      <br />
      <br />
      (5)权限名称:存储 权限用途:用于图片、数据的读取与保存 收集必要性说明:必须，若拒绝则无法使用血糖监测
      及分析功能，但不影响其他功能使用。
      <br />
      <br />
      (6)权限名称:通知 权限用途:用于接收系统发出的血糖报警信息 收集必要性说明:必须，若拒绝则无法接收血糖报警
      信息，但不影响其他功能使用。
      <br />
      <br />
      <span>7、自启动或关联启动说明</span>
      <br />
      <br />
      (1)推送相关:为确保本应用处于关闭或后台运行状 态下可正常接收到客户端推送的广播信息,本应用须使
      用(自启动)能力,将存在一定频率通过系统发送广播唤 醒本应用自启动或关联启动行为,是因实现功能及服务
      所必要的;当您打开内容类推送消息,在征得您的明确 同意后,会跳转打开相关内容。在未征得您同意的情况
      下,则不会有关联启动。
      <br />
      <br />
      (2)下载相关:当您在本APP内部下载文件(比如应用 内版本更新提示),根据下载状态处理HTTP连接并监控
      连接中的状态变化以及系统重启来确保每一个下载任 务顺利完成,特别是当用户切换不同的应用以后下载需
      要在后台继续进行(可能会有后台进程自启动),以及当 、车干 元反后获BAPK 件安装。
      <br />
      <br />
      (3)小组件:本应用支持桌面小组件功能,小组件功能 需要监听接收系统广播 (android.appwidget.action.APPWIDGET UPDATE)
      此广播为系统刷新桌面小组件广播,刷新与否由系统执 行;如果使用本应用的桌面小组件功能,本应用须使用
      自启动能力,通过接收系统广播唤醒本应用桌面小组件 相关服务(包括但不限于小组件数据的更新)。
      <br />
      <br />
      <span>（二）运营安全保障</span>
      <br />
      <br />
      为保障您使用我们的产品与服务时系统的稳定性和安全性、运营服务的质量和效率，我们会收集（包括在后台运行状态中）您的
      <span>
        设备信息（设备型号、设备Mac地址、Wifi使用地址、设备识别码、操作系统及软件版本、设备状态、网络状况）、日志信息、IP地址、位置信息
      </span>
      ，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对理糖宝系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。
      <br />
      <br />
      <span>（三）其他附加服务</span>
      <br />
      <br />
      为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用我们的产品或服务的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：
      <br />
      <br />
      1、基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫条形码用于绑定产品，拍摄照片或视频用于交流或记录生活习惯。
      <br />
      <br />
      2、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现更换头像、发表评论/分享或咨询提供证明等功能。
      <br />
      <br />
      3、基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现语音沟通。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。
      <br />
      <br />
      经您理解并同意，上述附加服务可能需要您在您的设备中开启您的摄像头（相机）、相册（图片库）、麦克风（语音）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
      <br />
      <br />
      <span>（四）依法豁免征得同意处理个人信息</span>
      <br />
      <br />
      请您理解，在下列情形中，根据法律法规，我们处理您的个人信息无需征得您的授权同意。
      <br />
      <br />
      1、 根据您的要求订立或履行合同所必需；
      <br />
      <br />
      2、 为履行法定职责或者法定义务所必需；
      <br />
      <br />
      3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
      <br />
      <br />
      4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
      <br />
      <br />
      5、在合理的范围内处理您自行公开的个人信息，或者其他已经合法公开的个人信息；
      <br />
      <br />
      6、 法律、行政法规规定的其他情形。
      <br />
      <br />
      <span>二、我们如何使用您的个人信息</span>
      <br />
      <br />
      1、为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账号和资金安全，我们会在以下情形中使用您的信息：
      <br />
      <br />
      （1）用来实现上述业务功能；
      <br />
      <br />
      （2）保障产品与服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；
      <br />
      <br />
      （3）维护和改进上述业务功能，开发新的业务功能；
      <br />
      <br />
      （4）处理您对理糖宝产品和/或服务的申请或反馈请求；
      <br />
      <br />
      （5）我们通过技术手段对数据进行去标识化处理后，去标识化处理的信息将无法识别主体。
      <br />
      <br />
      （6）在您同意的情况下，更好地了解您及您对产品及/或服务的意见和建议，增加我们与您的互动。
      <br />
      <br />
      2、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏展示，以保护您的信息安全。
      <br />
      <br />
      3、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。如果您选择使用本隐私政策中尚未列明的其他产品和/或服务时，在我们收集您的个人信息前，我们会通过业务协议、页面提示的方式向您详细说明信息收集的目的、方式、范围并征求您的明确同意。若您不同意提供前述信息，您可能无法使用该项服务，但不影响您使用其他服务。
      <br />
      <br />
      <span>4、我们如何委托处理、共享、转让、公开披露您的个人信息</span>
      <br />
      <br />
      <span>（1）委托处理</span>
      <br />
      <br />
      本业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。
      <br />
      <br />
      对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      <br />
      <br />
      <span>（2）共享</span>
      <br />
      <br />
      目前，我们与以下第三方共享您的个人信息，前提是已获得您的明确同意或是您自主选择向其披露的第三方。
      <br />
      <br />
      a)
      与授权合作伙伴共享：我们可能会向关联公司、合作伙伴等第三方共享您的个人信息，以提供更好的客户服务和用户体验；但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。该等第三方无权将共享的个人信息用于任何其他用途。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的协议、本隐私政策要求的保密和安全措施来处理个人信息。在敏感个人信息使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护敏感个人信息。
      <br />
      <br />
      b)为确保部分独立功能的实现，使你能够使用和享受更多的服务及功能，我们会在应用中嵌入第三方的SDK，我们将审慎评估该等SDK的使用目的。了解更多有关SDK处理个人信息的目的、方式及范围等信息。
      <br />
      <br />
      c)我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
      <br />
      <br />
      <span>（3）转让</span>
      <br />
      <br />
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：
      <br />
      <br />
      a) 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      <br />
      <br />
      b)
      在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      <br />
      <br />
      <span>（4）公开披露</span>
      <br />
      <br />
      我们仅会在以下情形下，公开披露您的个人信息：
      <br />
      <br />
      a) 获得您明确同意后；
      <br />
      <br />
      b) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
      <br />
      <br />
      （5）在以下情形中，收集、使用个人信息无需事先征得您的授权同意：
      <br />
      <br />
      a）与国家安全、国防安全直接相关的；
      <br />
      <br />
      b）与公共安全、公共卫生、重大公共利益直接相关的；
      <br />
      <br />
      c）与犯罪侦查、起诉、审判和判决执行等直接相关的；
      <br />
      <br />
      d）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      <br />
      <br />
      e）所收集的您的个人信息是您自行向社会公众公开的；
      <br />
      <br />
      f）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      <br />
      <br />
      g）根据您的要求签订或履行合同所必需的；
      <br />
      <br />
      h）用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
      <br />
      <br />
      i）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      <br />
      <br />
      j）法律法规规定的其他情形。
      <br />
      <br />
      <span>三、我们如何存储和保护您的个人信息</span>
      <br />
      <br />
      <span>1、个人信息存储地点</span>
      <br />
      <br />
      我们依照法律法规的规定，将在中华人民共和国境内运营中收集和产生的个人信息和重要数据存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果因业务需要，确需向境外提供的，我们将遵循相关国家规定或者征求您的同意。
      <br />
      <br />
      <span>2、个人信息存储期限</span>
      <br />
      <br />
      我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非法律法规另有规定或者您另行授权同意的除外。例如，对于包含个人信息的相关网络日志信息，我们会为您保存六个月；对包含个人信息的交易信息我们会为您保存三年。超出期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
      <br />
      <br />
      <span>3、我们如何保护您的个人信息</span>
      <br />
      <br />
      （1）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
      <br />
      <br />
      （2）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      <br />
      <br />
      （3）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      <br />
      <br />
      （4）当理糖宝停止运营其产品或服务时，我们会：
      <br />
      <br />
      a）及时停止继续收集个人信息；
      <br />
      <br />
      b）将停止运营的通知以逐一送达或公告的形式通知您；
      <br />
      <br />
      c）对所持有的个人信息进行删除或匿名化处理。
      <br />
      <br />
      （5）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      <br />
      <br />
      <span>四、您的权利</span>
      <br />
      <br />
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      <br />
      <br />
      <span>1、访问、变更您的个人信息</span>
      <br />
      <br />
      （1）您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：理糖宝APP首页-个人中心-编辑资料。
      <br />
      <br />
      （2）如果您无法通过上述路径访问这些个人信息，您可以理糖宝APP的我的客服随时与我们联系。
      <br />
      <br />
      （3）对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，您可以通过理糖宝APP的我的客服随时与我们联系。
      <br />
      <br />
      <span>2、更正您的个人信息</span>
      <br />
      <br />
      （1）当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正，经对您的身份进行验证后，您可以通过“1、访问、变更您的个人信息”中罗列的方式提出更正申请。
      <br />
      <br />
      （2）如果您无法通过上述路径更正这些个人信息，您可以通过理糖宝APP的我的客服随时与我们联系。
      <br />
      <br />
      <span>3、删除您的个人信息</span>
      <br />
      <br />
      （1）在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过理糖宝APP的我的客服随时与我们联系：
      <br />
      <br />
      a）如果我们处理个人信息的行为违反法律法规；
      <br />
      <br />
      b）如果我们收集、使用您的个人信息，却未征得您的同意；
      <br />
      <br />
      c）如果我们处理个人信息的行为违反了与您的约定；
      <br />
      <br />
      d）如果您不再使用我们的产品或服务，或您注销了账号。
      <br />
      <br />
      e）如果我们不再为您提供产品或服务。
      <br />
      <br />
      （2）若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      <br />
      <br />
      （3）当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      <br />
      <br />
      <span>4、改变您授权同意的范围</span>
      <br />
      <br />
      （1）每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意，您可以通过手机系统的隐私设置改变您授权同意的范围。
      <br />
      <br />
      （2）当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      <br />
      <br />
      <span>5、个人信息主体注销账户</span>
      <br />
      <br />
      您随时可注销此前注册的账户，您可以通过联系理糖宝APP-我的-个人信息注销账户。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
      <br />
      <br />
      <span>6、个人信息主体获取个人信息副本</span>
      <br />
      <br />
      您有权获取您的个人信息副本，您可以通过本隐私政策第七条提供的联系方式与我们联系。
      <br />
      <br />
      <span>7、响应您的上述请求</span>
      <br />
      <br />
      （1）为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。如您不满意，还可以向监管部门进行投诉。
      <br />
      <br />
      （2）对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      <br />
      <br />
      （3）在以下情形中，我们将无法响应您的请求：
      <br />
      <br />
      a）与个人信息控制者履行法律法规规定的义务相关的；
      <br />
      <br />
      b）与国家安全、国防安全直接相关的；
      <br />
      <br />
      c）与公共安全、公共卫生、重大公共利益直接相关的；
      <br />
      <br />
      d）与犯罪侦查、起诉、审判和执行判决等直接相关的；
      <br />
      <br />
      e）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
      <br />
      <br />
      f）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      <br />
      <br />
      g）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
      <br />
      <br />
      h）涉及商业秘密的。
      <br />
      <br />
      <span>五、我们如何处理未成年人的个人信息</span>
      <br />
      <br />
      1、我们非常重视对未成年人个人信息的保护。我们的产品和服务面向成年人。不管是否得到父母或监护人的同意，未成年人都不应创建自己的个人信息主体账户。如果我们发现自己收集了未成年人的个人信息，则会设法尽快删除相关数据。
      <br />
      <br />
      2、尽管当地法律和习俗对未成年人的定义不同，但我们将不满18周岁的任何人均视为未成年人。
      <br />
      <br />
      <span>六、本隐私政策如何更新</span>
      <br />
      <br />
      我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布更新版本，并在您登录及版本更新时以推送通知、弹窗或其他符合法律要求的适当形式向您展示变更后的内容。
      <!-- <br><br>2、本隐私政策所指的重大变更包括但不限于： -->
      <!-- <br><br>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        <br><br>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        <br><br>（3）个人信息共享、转让或公开披露的主要对象发生变化；
        <br><br>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
        <br><br>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        <br><br>（6）个人信息安全影响评估报告表明存在高风险时。
        <br><br>3、我们还会将本隐私政策的旧版本存档，供您查阅。 -->
      <br />
      <br />
      <span>七、如何联系我们</span>
      <br />
      <br />
      1、如果您对本隐私政策有任何疑问、意见或建议，通过我们的客户服务热线与我们联系：400-014-1088。
      <br />
      <br />
      2、如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向监管部门进行投诉或举报。
      <br />
      <br />
      3、您和我们就本隐私政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，双方同意应将争议提交至淮北市有管辖权的人民法院诉讼解决。
      <br />
      <br />
      <span>八、关键词定义</span>
      <br />
      <br />
      1、“关联公司”：指安徽糖仁健康科技有限责任公司直接或间接控制的公司、直接或间接控制安徽糖仁健康科技有限责任公司的公司、或与安徽糖仁健康科技有限责任公司共同受同一主体直接或间接控制或者施加重大影响的公司。“关联”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
      <br />
      <br />
      2、“个人信息”：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。本定义出自于《中华人民共和国个人信息保护法》，以下为个人信息示例（非理糖宝APP所收集的个人信息，仅作示例说明）
      <br />
      <br />
      个人信息举例如下：
      <br />
      <br />
      个人基本信息：个人姓名、生日、性别、民族、国籍、家庭关系、地址、个人电话号码、电子邮箱等
      <br />
      <br />
      个人身份信息：身份证、军官证、护照、驾驶证、工作证、出入证、社保卡、居住证等
      <br />
      <br />
      个人生物识别信息：个人基因、指纹、声纹、掌纹、耳廓、虹膜、面部识别特征等
      <br />
      <br />
      网络身份标识信息：个人信息主体账号、 IP 地址、个人数字证书等
      <br />
      <br />
      个人健康生理信息：个人因生病医治等产生的相关记录，如病症、住院志、医嘱单、检验报告、手术及麻醉记录、护理记录、用药记录、药物食物过敏信息、生育信息、以往病史、诊治情况、家族病史、现病史、传染病史等，以及与个人身体健康状况相关的信息，如体重、身高、肺活量等
      <br />
      <br />
      个人教育工作信息:个人职业、职位、工作单位、学历、学位、教育经历、工作经历、培训记录、成绩单等
      <br />
      <br />
      个人财产信息：银行账户、鉴别信息(口令)、存款信息（包括资金数量、支付收款记录等）、房产信息、信贷记录、征信信息、交易和消费记录、流水记录等，以及虚拟货币、虚拟交易、游戏类兑换码等虚拟财产信息
      <br />
      <br />
      个人通信信息：通信记录和内容、短信、彩信、电子邮件，以及描述个人通信的数据（通常称为元数据）等
      <br />
      <br />
      联系人信息：通讯录、好友列表、群列表、电子邮件地址列表等
      <br />
      <br />
      个人上网记录：指通过日志储存的个人信息主体操作记录，包括网站浏览记录、软件使用记录、点击记录、收藏列表等
      <br />
      <br />
      个人常用设备信息：指包括硬件序列号、设备 MAC 地址、软件列表、唯一设备识别码（如IMEI/Android
      ID/IDFA/OpenUDID/GUID/SIM 卡 IMSI 信息等）等在内的描述个人常用设备基本情况的信息
      <br />
      <br />
      个人位置信息：包括行踪轨迹、精准定位信息、住宿信息、经纬度等
      <br />
      <br />
      其他信息：婚史、宗教信仰、性取向、未公开的违法犯罪记录等
      <br />
      <br />
      3、“敏感个人信息”：是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。本定义出自于《中华人民共和国个人信息保护法》，以下为敏感个人信息示例（非理糖宝APP所收集的个人信息，仅作示例说明）
      <br />
      <br />
      敏感个人信息举例如下：
      <br />
      <br />
      个人财产信息：银行账户、鉴别信息(口令)、存款信息（包括资金数量、支付收款记录等）、房产信息、信贷记录、征信信息、交易和消费记录、流水记录等，以及虚拟货币、虚拟交易、游戏类兑换码等虚拟财产信息
      <br />
      <br />
      个人健康生理信息：个人因生病医治等产生的相关记录，如病症、住院志、医嘱单、检验报告、手术及麻醉记录、护理记录、用药记录、药物食物过敏信息、生育信息、以往病史、诊治情况、家族病史、现病史、传染病史等
      <br />
      <br />
      个人生物识别信息：个人基因、指纹、声纹、掌纹、耳廓、虹膜、面部识别特征等
      <br />
      <br />
      个人身份信息：身份证、军官证、护照、驾驶证、工作证、社保卡、居住证等
      <br />
      <br />
      其他信息:
      性取向、婚史、宗教信仰、未公开的违法犯罪记录、通信记录和内容、通讯录、好友列表、群组列表、行踪轨迹、网页浏览记录、住宿信息、精准定位信息等。
      <br />
      <br />
      <span>九、二维码扫描权限</span>
      <br />
      <br />
      二维码扫描权限说明：我们的应用程序需要使用相机权限来扫描二维码。
      <br />
      <br />
      权限用途： 我们使用相机权限来扫描二维码，目的是为了-
      提供更便捷的用户体验，如关注好友，添加医院，更好的进行糖尿病控糖操作
      <br />
      <br />
      隐私和安全：我们尊重您的隐私，并确保在扫描二维码时不会收集或存储任何个人数据。扫描的结果仅用于应用内部功能，不会与第三方共享。
      请注意，我们仅在需要使用相机扫描二维码时请求此权限。如果您不希望授予相机权限，您仍然可以使用应用程序的其他功能，但二维码扫描功能将不可用。
      如果您有任何问题或需要更多信息，请查看我们的隐私政策或联系我们的支持团队。
    </p>
  </div>
</template>

<script>
  export default {
    mounted() {
      localStorage.setItem('loginStatus', true)

      this.$once('hook:beforeDestroy', () => {
        // 页面关闭
        console.log('关闭页面')
        localStorage.setItem('loginStatus', false)
      })
    },
  }
</script>

<style scoped>
  .protocol-container {
    padding: 40px 20px;
    text-align: left;
  }
  ::v-deep .van-nav-bar__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  p {
    line-height: 20px;
    font-size: 14px;
  }
  span {
    font-weight: bold;
  }
</style>
