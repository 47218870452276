import { render, staticRenderFns } from "./barChart.vue?vue&type=template&id=cc6ec268&scoped=true&"
import script from "./barChart.vue?vue&type=script&lang=js&"
export * from "./barChart.vue?vue&type=script&lang=js&"
import style0 from "./barChart.vue?vue&type=style&index=0&id=cc6ec268&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc6ec268",
  null
  
)

export default component.exports