var ws = {
  // 下面是socket相关参数
  socket: null,
  heartbeatInterval: null,
  reconnectTimeout: null,
  heartbeatTimeout: null,
  heartbeatMsg: 'ping',
  reconnectDelay: 5000, // 5 seconds
  pongReceived: false,
  uid: '',
  // 下面是socket回调函数
  type1: null,
  type2: null,
  type3: null,
  type4: null,
  type5: null,
  type6: null,
  start() {
    this.init()
  },
  init() {
    if (JSON.parse(localStorage.getItem('appuser'))) {
      this.uid = JSON.parse(localStorage.getItem('appuser')).id
      // 处理 WebSocket 事件
      this.initWebSocket()
      console.log('已登录------》初始化WebSocket')
    } else {
      console.log('未登录-----》中断WebSocket')
    }
  },

  // socket事件
  initWebSocket() {
    // if(this.uid){
    const socketUrl =
      // 线上地址
      `wss://api.cgm.go-sugar.cn/webws/WebSocket?uid=${this.uid}`
    this.socket = new WebSocket(socketUrl)
    this.socket.addEventListener('open', () => {
      console.log('open-连接已打开', event)
      this.startHeartbeat()
    })
    this.socket.addEventListener('message', () => {
      const type = JSON.parse(event.data).type
      console.log('message-来自服务器的消息: ', type)
      // 如果断连了
      if (type == '0') {
        this.resetHeartbeat()
        this.pongReceived = true
      } else if (type == '1') {
        if (this.type1 != null) {
          console.log('执行回调函数')
          this.type1()
        }
      }
    })

    this.socket.addEventListener('close', () => {
      console.log('close-连接已关闭', event)
      // 清楚各种定时器
      this.cleanup()
      this.reconnect()
    })

    this.socket.addEventListener('error', () => {
      console.error('error-连接发生错误: ', event)
      this.cleanup()
      this.reconnect()
    })
  },

  // 向服务器发送消息
  // handleMessage(event) {
  //   const type = JSON.parse(event.data).type;
  //   console.log("message-来自服务器的消息: ", type);
  //   // 如果断连了
  //   if (type == "0") {
  //     this.resetHeartbeat();
  //     this.pongReceived = true;
  //   } else if (type == "1") {
  //     if (this.type1 != null) {
  //       this.type1();
  //     }
  //   }
  // },
  // handleOpen(event) {
  //   console.log("open-连接已打开", event);
  //   this.startHeartbeat();
  // },
  // handleClose(event) {
  //   console.log("close-连接已关闭", event);
  //   // 清楚各种定时器
  //   this.cleanup();
  //   this.reconnect();
  // },
  // handleError(event) {
  //   console.error("error-连接发生错误: ", event);
  //   this.cleanup();
  //   this.reconnect();
  // },
  // 每 30 秒发送一次心跳消息，如果 WebSocket 连接是打开的
  startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.pongReceived = false
        this.socket.send(this.heartbeatMsg)
        console.log('Heartbeat sent: ', this.heartbeatMsg)

        // 3秒钟内如果没有收到pong则关闭socket，触发重连
        this.heartbeatTimeout = setTimeout(() => {
          if (!this.pongReceived) {
            console.log('没收到pong')
            this.socket.close()
          }
        }, 3000)
      }
    }, 30000) // 每30秒发一次心跳
  },

  resetHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
    if (this.heartbeatTimeout) {
      clearTimeout(this.heartbeatTimeout)
    }
    this.startHeartbeat()
  },
  cleanup() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout)
    }
    if (this.heartbeatTimeout) {
      clearTimeout(this.heartbeatTimeout)
    }
  },
  reconnect() {
    console.log('我开始重连了。。。')
    this.reconnectTimeout = setTimeout(() => {
      this.initWebSocket()
    }, this.reconnectDelay)
  },
}

ws.start()

export default ws
